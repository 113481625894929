import { faMailReplyAll, faMessage } from "@fortawesome/free-solid-svg-icons";

import { FloatActionService } from "../../../services";
import type {
  AddNoteButtonType,
  ConnectionButtonType,
  DataEntryButtonType,
  DirectionButtonType,
  EditButtonType,
  EmailButtonType,
  HelpdeskButtonType,
  PrintButtonType,
  SystemLogButtonType,
  WorkflowHistoryButtonType,
} from "./types";

export const connectionFloatButton = (service: FloatActionService, config: ConnectionButtonType) => {
  service.add({
    ...config,
    text: "plusbutton.connect",
    icon: {
      type: "adaa",
      value: "adaa-icon-float-connections",
    },
  });
};

export const emailFloatButton = (service: FloatActionService, config: EmailButtonType) => {
  service.add({
    ...config,
    text: "login.email",
    icon: {
      type: "fa",
      value: faMailReplyAll,
    },
  });
};

export const printFloatButton = (service: FloatActionService, config: PrintButtonType) => {
  service.add({
    ...config,
    text: "plusbutton.print",
    icon: {
      type: "adaa",
      value: "adaa-icon-float-print",
    },
  });
};

export const helpdeskButton = (service: FloatActionService, config: HelpdeskButtonType) => {
  service.add({
    ...config,
    text: "plusbutton.newhelpdeskticket",
    icon: {
      type: "adaa",
      value: "adaa-icon-new",
    },
  });
};

export const workflowHistoryButton = (service: FloatActionService, config: WorkflowHistoryButtonType) => {
  service.add({
    ...config,
    text: "plusbutton.workflow_history",
    icon: {
      type: "adaa",
      value: "adaa-icon-console_workflow",
    },
  });
};

export const editButton = (service: FloatActionService, config: EditButtonType) => {
  service.add({
    ...config,
    text: "tooltips.edit",
    icon: {
      type: "adaa",
      value: "adaa-icon-edit",
    },
  });
};

export const dataEntryButton = (service: FloatActionService, config: DataEntryButtonType) => {
  service.add({
    ...config,
    text: "breadcrumbs.data-entry",
    icon: {
      type: "adaa",
      value: "adaa-icon-console_data_entry_kpi_actuals",
    },
  });
};

export const directionFloatButton = (service: FloatActionService, config: DirectionButtonType) => {
  service.add({
    ...config,
    text: "directions.title",
    icon: {
      type: "adaa",
      value: "adaa-icon-adaa-messages",
    },
  });
};

export const addNoteButton = (service: FloatActionService, config: AddNoteButtonType) => {
  service.add({
    ...config,
    text: "objectives.add_notes",
    icon: {
      type: "fa",
      value: faMessage,
    },
  });
};

export const systemLogButton = (service: FloatActionService, config: SystemLogButtonType) => {
  service.add({
    ...config,
    text: "audit_trace.title",
    icon: {
      type: "adaa",
      value: "adaa-icon-console_audit_trail",
    },
  });
};
