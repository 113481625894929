import { inject } from "@angular/core";

import { AdaaHelper } from "../../../../core/utils";
import { FloatActionService } from "../../../services";
import type { CommonFloatActionButtonType } from "./types";
import {
  addNoteButton,
  connectionFloatButton,
  dataEntryButton,
  directionFloatButton,
  editButton,
  emailFloatButton,
  helpdeskButton,
  printFloatButton,
  systemLogButton,
  workflowHistoryButton,
} from "./utils";

export const genericFloatButtons = () => {
  const service = inject(FloatActionService);
  const isArchived = AdaaHelper.isPlanArchived();

  const fn = (list: CommonFloatActionButtonType[]) => {
    for (const config of list) {
      switch (config.key) {
        case "direction": {
          directionFloatButton(service, config);
          break;
        }

        case "print": {
          printFloatButton(service, config);
          break;
        }

        case "helpdesk": {
          helpdeskButton(service, config);
          break;
        }

        case "data-entry": {
          dataEntryButton(service, config);
          break;
        }

        case "edit": {
          if (!isArchived) editButton(service, config);
          break;
        }

        case "workflowHistory": {
          workflowHistoryButton(service, config);
          break;
        }

        case "addNote": {
          addNoteButton(service, config);
          break;
        }

        case "systemLog": {
          systemLogButton(service, config);
          break;
        }

        case "email": {
          emailFloatButton(service, config);
          break;
        }

        case "connection": {
          connectionFloatButton(service, config);
          break;
        }
      }
    }
  };

  return fn;
};
