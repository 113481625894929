import type { ValueText } from "../models";

export class Constants {
  static EMPLOYEE_PULSE_IFRAME_URL = "https://fe-adaa-angular-powerbi-viewer-prod.azurewebsites.net/report";

  static imagePlaceholder = "/assets/images/profile_placeholder.png";

  static localStorageKeys = {
    sessionId: "session_id",
    language: "language",
    currentEntity: "current_entity",
    currentPlan: "current_plan",
    currentMap: "current_map",
    firstVisit: "first_visit",
    lastUser: "last_user",
    lastUrlVisited: "last_url_visited",
    checkLogin: "check_login",
    hardwareUuid: "hardware_uuid",
    user: "user",
    uaePassLogin: "uae_pass_login",
    tpCycle: "tp-cycle",
    tpTab: "tp-tab",
    dataEntryKpiType: "data-entry-kpi-type",
    agmCycle: "agm-cycle",
  };

  static yearDate = "yyyy";
  static monthYearDate = "MMM/yyyy";
  static shortMonthYearDate = "MM/yyyy";
  static normalDate = "dd/MM/yyyy";
  static dateTime = "dd/MM/YYYY HH:mm";

  static uaeTimezone = "+04:00";
  static uaeTimezoneName = "Asia/Dubai";
  static months = {
    en: ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    ae: [
      "",
      "يناير",
      "فبراير",
      "مارس",
      "أبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
  };

  static mobileScreenBreakpoint = 830;

  static YEAR_RANGE = 27;

  static DYNAMIC_LINKS_LOOKUP_ID = {
    REPORTING_TOGGLER: 102417,
    DASHBOARD: 102419,
    DASHBOARD_TOGGLER: 102418,
    GRAPH_TOGGLER: 102600,
    GRAPH_PILLAR_TOGGLER: 102614,
    TRANSFORMATIONAL_PROJECT_CANCEL_STATUS: 102846,
    TRANSFORMATIONAL_PROJECT_READY_TO_PUBLISH: 102912,
    ENTITY_SIZE_TYPE_FILTERS: 103059,
    NEW_EMPLOYEE_PULSE_DASHBOARD: 103061,
    UAE_PASS_LOGIN: 103102,
    NATIONAL_STRATEGIES_LINK_TO_NT_ENABLED: 103459,
  };

  static NOTIFICATION_ACTION_TYPES = {
    CREATE: 163,
    UPDATE: 164,
    DELETE: 165,
    DATA_ENTRY: 242,
    PROGRESS_UPDATE: 243,
    LINK_TO_OBJECTIVE: 244,
    AUDIT_DATA_ENTRY: 490,
  };

  static CONSTANT_KPI_ANNUAL_WEIGHTS = {
    NKPI: 1037,
    SKPI: 1038,
    OPM: 1039,
    DKPI: 1040,
    EKPI: 1041,
    SRVKPI: 1082,
    MOKPI: 1053,
    MTKPI: 1054,
  };

  static CHART_FREQUENCY_OPTION = {
    ONE_YEAR: "1YEAR",
    TWO_YEAR: "2YEAR",
    CYCLE: "CYCLE",
    FULL_TREND: "FULLTREND",
    MONTH: "MONTH",
    ONE_YEAR_NO_MONTHS: "ONE_YEAR_NO_MONTHS",
  };

  static CONSTANT_WORKFLOW = {
    OBJECTIVE: 279,
    INITIATIVE: 280,
    ACTIVITY: 281,
    SERVICE: 282,
    TASK: 283,
    NKPI: 284,
    SKPI: 285,
    MOKPI: 553,
    MTKPI: 544,
    OKPI: 286,
    DKPI: 287,
    EKPI: 288,
    SEKPI: 289,
    CYCLES: 290,
    METRIC_ACTUALS: 294,
    CALCULATION_READING: 295,
    WFTYPES: 33,
    ACTIONTYPES: 32,
    LEVELS: 64,
    ITEMTYPES: 50,
    GOVDIRECTIONS: 457,
    MILESTONE_TARGETS: 554,
    NATIONAL_TARGET: 555,
    DIRECTIONAL_TARGET: 556,
    NATIONAL_PROJECTS: 560,
    PROJECT_MILESTONES: 561,
    PROJECT_SUB_MILESTONES: 562,
    ANNUAL_PROJECT_PROJECTS: 563,
    ANNUAL_PROJECT_MILESTONES: 564,
  };

  static CONSTANT_WORKFLOW_TRIGGERS = {
    RULES: 78,
    OPERATORS: 79,
    ITEMTYPES: 50,
    INITIATIVE: 2,
    ACTIVITY: 3,
    TASKS: 5,
    SERVICES: 4,
    NKPI: 6,
    SKPI: 7,
    OKPI: 8,
    DKPI: 9,
    EKPI: 10,
    SEKPI: 11,
    CYCLES: 12,
  };

  static CONSTANT_PERMISSIONS = {
    CYCLES: 95,
    DELIVERY_MODULE_ACCESS: 96,
    EXPORT_DATA: 97,
    PUBLISH_PLAN: 98,
    MINISTRY_DASHBOARD: 99,
    NOTIFICATIONS: 100,
    AUDIT_DATA: 101,
    AUDIT_PLAN: 102608,
    SDG_DASHBOARD: 102616,
    DATA_ENTRY: 102,
    SRVKPI: 103,
    OPM: 104,
    EKPI: 105,
    SKPI: 106,
    DKPI: 107,
    NKPI: 108,
    MOKPI: 493,
    MTKPI: 494,
    NTKPI: 655,
    DTKPI: 656,
    ORG_STRUCTURE: 110,
    ENTITY_ADDRESSES: 111,
    ENTITIES: 112,
    USER_GROUPS: 113,
    USER_ROLES: 114,
    USERS: 115,
    EXTENDEND_ATTRIBUTES: 116,
    DIMENSIONS: 117,
    METRICS: 118,
    PILLARS: 119,
    SERVICES_KHADAMATI: 126,
    GOVERNMENT_DIRECTIONS: 127,
    ACTIVITIES: 128,
    INITIATIVES: 129,
    OBJECTIVES: 130,
    MANDATORY: 226,
    EXECUTIVE_TEAMS: 227,
    PROVISIONING: 228,
    FORMULAS: 229,
    HELP: 230,
    HELP_FAQS: 319,
    WORKFLOW: 245,
    ORG_UNIT: 259,
    STRATEGIC_DOCUMENT: 260,
    FAVORITE: 262,
    TRANSLATIONS: 263,
    TEXTMAPPING: 274,
    RULES_MANAGEMENT: 314,
    LOOKUP_MANAGEMENT: 313,
    AUDIT_TRAIL: 390,
    KHADAMATI_INTEGRATION: 312,
    PLAN_NAVIGATION: 400,
    ENTITY_MIGRATION: 409,
    ANALYSIS: 431,
    CALCULATION_MANAGEMENT: 432,
    PLAN_REVIEW: 444,
    ONLINE_HELP: 451,
    REPORTS: 453,
    WORKFLOW_MONITORING: 454,
    WF_CONFIGURATION: 462,
    WF_TRIGGER: 461,
    DOC_LIBRARY: 469,
    GOV_SCORE_KPI: 658,
    AUDIT_RESULTS: 102601,
    KPI_ANNUAL_WEIGHTS: 102605,
    NATIONAL_PROJECTS: 102624,
    NATIONAL_PROJECTS_DASHBOARD: 102632,
    NATIONAL_PROJECT_MILESTONES: 102625,
    HOMEPAGE: 102643,
    MAIN_OBJECTIVE: 102655,
    STRATEGIC_PROJECT: 102656,
    STRATEGIC_ACTIVITIES: 102657,
    TRANSFORMATIONAL_ObJECTIVES: 102743,
    OTHER_ENTITIES: 102745,
    USERS_SECURITY: 102756,
    TP_CYCLES: 102825,
    TRANSFORMATIONAL_TARGETS: 102905,
    TRANSFORMATIONAL_PROJECT_AGREEMENT: 102960,
    TRANSFORMATIONAL_PROJECTS_READY_TO_PUBLISH: 103050,
    ANNUAL_MEETINGS_PROJECTS: 103250,
    ANNUAL_MEETINGS_PROJECTS_MILESTONES: 103251,
    ANNUAL_MEETINGS_PROJECTS_CYCLES: 103252,
    GOVERNMENT_SCORES: 103280,
    DIMENSIONS_SYNC: 103700,
    DIMENSIONS_LOGS: 103703,
    DATA_ENTRY_TIMELINE: 95,
  } as const;

  static CYCLES = {
    STATE: {
      ACTIVE: 1,
      DRAFT: 3,
      CLOSED: 4,
      ARCHIVED: 5,
      PLANNING: 6,
    },
  };

  static ITEM_TYPES_DOCS = {
    ADAA2: 469,
    GENERATED_REPORTS: 479,
  };

  static SERVICES = {
    STATE: {
      ACTIVE: 1,
      DRAFT: 3,
      IN_REVIEW: 7,
    },
  };

  static CONSTANT_PERMISSION_TYPES = {
    VIEW: "view",
    CREATE: "create",
    MANAGE: "manage",
    EXECUTE: "execute",
  };

  static CONSTANT_COLORS = {
    EVALUATION_GREEN: 29,
    EVALUATION_RED: 30,
    EVALUATION_YELLOW: 31,
    EVALUATION_OTHER: 32,
    EVALUATION_LIME: 33,
    EVALUATION_GRAY: 34,
    EVALUATION_BLUE: 35,
    EVALUATION_LIGHTBLUE: 36,
    //CUSTOM COLOR ONLY FE
    EVALUATION_GOLD: 70,
    EVALUATION_LIGHTGRAY: 71,
    EVALUATION_BLACK: 72,
  };

  static DELIVERY_MODULE_PROGRESS_STATUS = [
    {
      colorCode: Constants.CONSTANT_COLORS.EVALUATION_RED,
      label: "delivery_module.prog_status.red",
      lowerLimit: 0,
      upperLimit: 25,
      lookupPropValue: 1,
    },
    {
      colorCode: Constants.CONSTANT_COLORS.EVALUATION_YELLOW,
      label: "delivery_module.prog_status.ambar",
      lowerLimit: 25,
      upperLimit: 50,
      lookupPropValue: 2,
    },
    {
      colorCode: Constants.CONSTANT_COLORS.EVALUATION_LIME,
      label: "delivery_module.prog_status.lime",
      lowerLimit: 50,
      upperLimit: 75,
      lookupPropValue: 3,
    },
    {
      colorCode: Constants.CONSTANT_COLORS.EVALUATION_GREEN,
      label: "delivery_module.prog_status.green",
      lowerLimit: 75,
      upperLimit: 100,
      lookupPropValue: 4,
    },
    {
      colorCode: Constants.CONSTANT_COLORS.EVALUATION_GREEN,
      label: "delivery_module.prog_status.green",
      lowerLimit: 100,
      upperLimit: 100 ^ 10000000,
      lookupPropValue: 4,
    },
  ];

  static COLOR_CODES = {
    [Constants.CONSTANT_COLORS.EVALUATION_GREEN]: "#8cc63f",
    [Constants.CONSTANT_COLORS.EVALUATION_RED]: "#c1272d",
    [Constants.CONSTANT_COLORS.EVALUATION_YELLOW]: "#fbb03b",
    [Constants.CONSTANT_COLORS.EVALUATION_OTHER]: "#838383",
    [Constants.CONSTANT_COLORS.EVALUATION_LIME]: "#c7ea46",
    [Constants.CONSTANT_COLORS.EVALUATION_GRAY]: "#7f7f7f",
    [Constants.CONSTANT_COLORS.EVALUATION_BLUE]: "#00a3e8",
    [Constants.CONSTANT_COLORS.EVALUATION_LIGHTBLUE]: "#77c8eb",
    [Constants.CONSTANT_COLORS.EVALUATION_GOLD]: "#d29f35",
    [Constants.CONSTANT_COLORS.EVALUATION_LIGHTGRAY]: "#bebebe",
    [Constants.CONSTANT_COLORS.EVALUATION_BLACK]: "#000000",
  };

  static CONSTANT_AGG_TYPES = {
    OVERALL_PERFORMANCE: 102560,
    STRATEGIC_PERFORMANCE: 102561,
    ENABLER_PERFORMANCE: 102562,
    OPERATIONAL_PERFORMANCE: 102563,
    SERVICE_PERFORMANCE: 102567,
  };

  static OBJECT_STATUS = {
    REMOVE: 2,
    IN_ACTIVE: 2,
    ACTIVE: 1,
    DRAFT: 3,
    CLOSED: 4,
    ARCHIVED: 5,
    PLANNING: 6,
    IN_REVIEW: 7,
  };

  static SUPPORT_TICKET_STATUS = {
    NEW: 173,
    IN_PROGRESS: 174,
    RESOLVED: 175,
    REOPEN: 176,
    CLOSED: 177,
  };

  static OBJECT_WF_STATUS = {
    REMOVE: "D",
    EDIT: "E",
    ACTIVE: "A",
    ON_APPROVAL: "O",
    REJECTED: "R",
  };

  static WF_ACTION_DECISION = {
    APPROVED: 3,
    REJECTED: 4,
    AMENDED: 5,
  };

  static CONSTANT_INTEGRITY_ERRORS = {
    RESPONSE_CODE: 2000,
    DEPENDENCY_ERROR: 2001,
    VALIDATION_ERROR: 2002,
    WARNING: 2003,
    ERROR_AND_WARNING: 2004,
    WARNING_MISSING_INPUT_DATA: 226,
  };

  static CONSTANT_ERROR_CODE = {
    NO_ENTRIES_ON_DATABASE: 5,
    NO_ENTRIES_ON_DATABASE_CODE2: 17,
    PASSWORD_WRONG_COMPLEXITY: 241,
    INVALID_OLD_PASSWORD: 56,
    AD_USER_NOT_EXISTS: 242,
    WORKFLOW_ERROR: 26,
    SESSION_EXPIRED: 18,
    FORBIDDEN: 23,
  };

  static ADDITIONAL_FIELDS_CODE = {
    WORKFLOW_SENT_TO_APPROVAL: "27",
  };

  static KPI_TYPE = {
    NKPI: 37,
    SKPI: 38,
    OPM: 39,
    UAE: 40,
    EKPI: 41,
    SRVKPI: 82,
    MOKPI: 53,
    MTKPI: 54,
    NTKPI: 55,
    DTKPI: 56,
    GSKPI: 58,
  } as const;

  static PAGE_TYPE = {
    OBJECTIVE: 42,
    INITIATIVE: 43,
    ACTIVITY: 44,
    SERVICE: 47,
  };

  static MEASUREMENT = {
    CONSTANT_MEASUREMENT_NUMBER: 6,
    CONSTANT_MEASUREMENT_CURRENCY: 7,
    CONSTANT_MEASUREMENT_PERCENTAGE: 8,
    CONSTANT_MEASUREMENT_RANK: 9,
    CONSTANT_MEASUREMENT_TEXT_MAPPING: 10,
  };

  static YTP_CALC = {
    AVERAGE: 25,
    LAST: 26,
    SUM: 28,
  };

  static TREND = {
    INCREASING: 140,
    DECREASING: 141,
    ONTARGET: 142,
    BOUNDED: 143,
  };

  static METRICS_TYPE = {
    CUSTOM: "C",
    GLOBAL: "G",
    LOCAL: "L",
    LOCAL_ALL: "LA",
    GLOBAL_LOCAL: "G/L",
    DIMENSION: "D",
  };

  static RULE_STATUS = {
    INACTIVE: 1,
    ACTIVE: 0,
  };

  static CONSTANT_WORKFLOW_WFTYPES = {
    APPROVAL: 167,
    REVIEW: 166,
  };

  static AdaaEntityTypes = {
    MINISTRY: 68,
    AUTHORITY: 69,
    EXTERNAL: 500,
  };

  static FREQUENCY = {
    MONTHLY: 12,
    QUARTERLY: 13,
    SEMIANNUAL: 14,
    ANNUAL: 15,
    EVERY_TWO_YEARS: 16,
    EVERY_THREE_YEAR: 17,
    EVERY_FOUR_YEARS: 18,
    EVERY_FIVE_YEARS: 19,
  };

  static FORMULA_STRING = {
    NOFORMULA: "NOFORMULAV",
    FORMULAWITHTARGETS: "FORMULAWITHTARGETS",
    FORMULA: "FORMULA",
  };

  static BASE_TEMPLATE_TYPES = {
    EMAIL: 121,
    APP: 135,
    SMS: 120,
  };

  static MAIN_TABLE_LIST_CONF_KEY = {
    IMPROVEMENT_PLAN: "improvement_plan_conf_list",
    GOV_DIRECTIONS: "gov_dir_conf_list",
    USERS: "user_conf_list",
    ACCESSPROFILES: "access_profiles_conf_list",
    ATTRIBUTES: "attributes_conf_list",
    OBJECTIVES: "objectives_conf_list",
    ENABLER_OBJECTIVES: "enabler_objectives_conf_list",
    INITIATIVES: "initiatives_conf_list",
    CYCLES: "cycles_conf_list",
    SERVICES: "services_conf_list",
    PILLARS: "pillar_conf_list",
    EXECUTIVE_TEAMS: "executive_teams_conf_list",
    EXECUTIVE_TEAM_MEMBERS: "executive_team_members_conf_list",
    EXECUTIVE_TEAM_NKPIS: "executive_team_nkpis_conf_list",
    METRICS: "metrics_conf_list",
    DIMENSIONS: "dimensions_conf_list",
    ACTIVITIES: "activities_conf_list",
    NEW_DS_ACTIVITIES: "new_ds_activities_conf_list",
    KPIS: "kpis_conf_list",
    SKPIS: "skpis_conf_list",
    EKPIS: "ekpis_conf_list",
    OPM: "opm_conf_list",
    SRVKPIS: "srvkpis_conf_list",
    NKPIS: "nkpis_conf_list",
    NTKPIS: "ntkpis_conf_list",
    DKPIS: "dkpis_conf_list",
    MTKPIS: "mtkpis_conf_list",
    MOKPIS: "mokpis_conf_list",
    IMPORT_EXPORT: "import_export_conf_list",
    ENTITIES: "entities_conf_list",
    MANDATORYS: "mandatory_conf_list",
    LOOKUPMANAGER: "lookup_table_management_config",
    LOOKUPCONTENT: "lookup_table_content_config",
    DATAENTRY: "data_conf_list",
    DATAENTRY_KPI: "data_kpi_conf_list",
    DATAENTRY_METRICS: "data_metrics_conf_list",
    FORMULAS: "formulas_conf_list",
    AUDIT_TRAIL: "audit_trail_conf_list",
    AUDIT_DATA: "audit_kpi_conf_list",
    AUDIT_ISSUES_LIST: "kpi_with_audit_issues_conf_list",
    TASKS: "tasks_conf_list",
    HELP_MODAL_LIST: "help_modal_conf_list",
    HELP_TOOLTIP_LIST: "help_tooltip_conf_list",
    RULE_SETS_LIST: "rule_sets_conf_list",
    USERS_ROLES: "user_roles_conf_list",
    USERS_GROUPS: "user_groups_conf_list",
    USERS_PROFILE_OWNED_OBJECT: "user_profile_owned_object_conf_list",
    USERS_RESPONSIBLE_FOR_OBJECT: "user_responsible_for_conf_list",
    NOTIFICATION_CONFIGURATION_PMO: "notification_configuration_pmo_conf_list",
    NOTIFICATION_CONFIGURATION_ENTITY: "notification_configuration_entity_conf_list",
    WORKFLOW_CONFIGURATION: "work_flow_configuration_list",
    WORKFLOW_MONITORING: "workflow_monitoring_list",
    WORKFLOW_MONITORING_PENDING_USER_LIST: "workflow_monitoring_pending_action_list",
    WORKFLOW_TRIGGERS_RULES: "workflow_configuration_triggers_rules",
    WORKFLOW_TRIGGERS: "workflow_configuration_triggers_list",
    HELPDESK_TICKETS: "helpdesk_tickets_conf_list",
    DELIVERY_MODULE_TASKS: "delivery_module_step_tasks_conf_list",
    DELIVERY_MODULE_LINKEDKPI: "delivery_module_linkedkpi_conf_list",
    DELIVERY_MODULE_LIST: "delivery_conf_list",
    DELIVERY_MODULE_RESOURCES: "delivery_module_resources_conf",
    TEXTMAPPING: "textmapping_conf_list",
    HOMEPAGE_ENTITY_UPDATES: "homepage_entity_updates",
    HOMEPAGE_ENTITY_UPDATES_SKPI: "homepage_entity_updates_skpi",
    HOMEPAGE_ENTITY_UPDATES_OPM: "homepage_entity_updates_opm",
    HOMEPAGE_KPI_UPDATES: "homepage_kpi_updates",
    HOMEPAGE_ENTITY_TASKS: "homepage_entity_tasks",
    HOMEPAGE_PILLARS: "homepage_pillars",
    HOMEPAGE_EXECUTIVE_TEAMS: "homepage_executive_teams",
    HOMEPAGE_ACTIVE_AUDITS: "homepage_active_audits",
    HOMEPAGE_FAVORITES: "homepage_favorites",
    HOMEPAGE_ACTIVE_NKPIS: "homepage_active_nkpis",
    HOMEPAGE_ACTIVE_DKPIS: "homepage_active_dkpis",
    HOMEPAGE_HIGH_PRIORITY: "homepage_high_priority",
    HOMEPAGE_MY_TASKS: "homepage_my_tasks",
    HOMEPAGE_ENTITIES_TASKS: "homepage_entities_tasks",
    HOMEPAGE_SUB_GOV_DIR: "homepage_subgovdir",
    HOMEPAGE_EKPI_PILLAR_LIST: "homepage_ekpi_by_pillar",
    HOMEPAGE_INITIATIVE_HIGH_PRIORITY: "homepage_initiative_high_priority",
    HOMEPAGE_INITIATIVE_ANNUAL_HIGH_PRIORITY: "homepage_initiative_annual_high_priority",
    HOMEPAGE_ENABLING_ENTITIES: "homepage_enabling_entities",
    NOTIFICATION_CONFIGURATION: "notifications_conf_list",
    HOMEPAGE_OBJECTIVES_ENTITIES: "homepage_objectives_conf_list",
    HOMEPAGE_OBJECTIVES_ENTITIES_YEAR: "homepage_objectives_year_conf_list",
    CONSOLE: "console_conf",
    KPI_ANNUAL_WEIGHTS_SUBMENU: "kpi_annual_weights_submenu",
    KHADAMATI: "khadamati_conf_list",
    KHADAMATI_DETAIL: "khadamati_conf_detail_list",
    LINKED_KPIS_LIST: "linked_kpis_conf_detail_list",
    USER_RELATED_OBJECTS: "user_related_objects_list",
    USER_PERMISSIONS_DETAIL: "user_permissions_detail",
    SUB_GOVERNMENT_DIRECTION_ICONS: "sub_government_direction_icons",
    RULES_MANAGEMENT: "integrity_rules_management",
    RULES_MANAGEMENT_LOGS: "integrity_rules_management_rules_logs",
    RULES_MANAGEMENT_RULES: "integrity_rules_management_rules",
    HELP_FAQS_CATEGORY: "help_faqs_category_conf_list",
    HELP_FAQS: "help_faqs_conf_list",
    SDG_INITIATIVES: "sdg_subgov_initiatives_list",
    SDG_TARGETS: "sdg_subgov_kpis_list",
    REPORTING_LIST: "reporting_list",
    ATTACHMENT_SM: "attachments_config",
    PROVISIONING_ADDRESSES: "provisioning_addresses",
    SERVICE_CARD_TABLE: "service_card_table_conf_list",
    OBJECTIVES_CARD_STRATEGIC_KPI: "objective_card_strategic_kpi_conf_list",
    OBJECTIVES_CARD_INITIATIVES: "objective_card_initiative_conf_list",
    OBJECTIVES_CARD_SERVICES: "objective_card_services_conf_list",
    OBJECTIVES_CARD_TRANSFORMATIONAL_PROJECT: "objective_card_transformational_project_conf_list",
    INITIATIVE_CARD_ACTIVITIES: "initiative_card_activity_conf_list",
    ACTIVITY_CARD_OPMKPI: "activity_card_opmkpi_conf_list",
    KPI_CARD_INITIATIVE: "kpi_card_initiative_conf_list",
    KPI_CARD_SUB_KPI: "kpi_card_sub_kpi_conf_list",
    SERVICE_CARD_CHILD_SERVICES: "service_card_child_services_conf_list",
    SERVICE_CARD_SRV_KPI: "service_card_srv_kpi_conf_list",
    ATTACHMENT_GENERAL: "attachment_general_conf_list",
    ATTACHMENT_DELIVERY_MODULE: "attachment_delivery_module_conf_list",
    HELPDESK_ATTACHMENTS: "helpdesk_attachment_conf_list",
    INTEGRITY_DEPENDENCIES: "integrity_dependencies_conf_list",
    BENCHMARK_LIST: "benchmark_conf_list",
    ENTITY_OFFICES: "entity_office_conf_list",
    SUBKPIS_STEP: "subkpis_step_conf_list",
    ACTIVITIES_STEP: "activities_step_conf_list",
    SUBKPIS_SELECT_EXISTENCE: "subkpis_select_existence_conf_list",
    USER_GROUP_USER_GRID: "user_group_user_grid_conf_list",
    USER_GROUP_USER_SELECTION: "user_group_user_selection_conf_list",
    REPORT_CYCLES_CONF_LIST: "report_cycles_conf_list",
    REPORT_ENTITIES_LIST: "report_entities_conf_list",
    REPORT_KPI_LIST: "report_kpi_conf_list",
    WORKFLOW_HISTORY: "workflow_history_conf_list",
    SUB_GOV_DIR: "sub_gov_dir_conf_list",
    USER_GROUP_SELECTION: "user_group_selection_conf_list",
    PERFORM_ANALYSIS_LIST: "perform_analysis_conf_list",
    USER_ROLES_LIST: "user_form_user_roles_conf_list",
    USER_GROUP_LIST: "user_form_user_groups_conf_list",
    CALCULATION_QUEUE_LIST: "calculation_queue_list_config",
    CALCULATION_QUEUE_LIST_PERIODS: "calculation_queue_list_periods_config",
    CALCULATION_QUEUE_LIST_SEARCH: "calculation_queue_list_config_search",
    USER_CONCERNED_ENTITIES_LIST: "user_form_concerned_entities_conf_list",
    PLAN_REVIEW_LIST: "plan_review_approve_conf_list",
    CONTEXTUAL_HELP_SUB_LIST: "contextual_help_create_conf_list",
    FORMULA_HELPER_KPI_FORM: "formula_helper_kpi_form_conf_list",
    DELIVERY_MODULE_RESOURCES_LIST: "delivery_module_resource_conf_list",
    STRATEGIC_CYCLE_ENTITIES: "strategic_cycles_entities_conf_list",
    SMART_TOOL_TIPS_LIST_FORM: "smart_tool_tips_list_form",
    ATTR_TEXT_MAP_LIST: "attr_text_mapping_conf_list",
    BENCHMARK_ATTACHMENT_LIST: "benchmark_attachment_list_conf_list",
    DIMENSIONS_NAME_LIST: "dimensions_name_conf_list",
    DIMENSIONS_GEOLOCATION_LIST: "dimensions_geolocation_conf_list",
    DATA_ENTRY_METRICS_LIST: "data_entry_metrics_conf_list",
    TEXT_MAPPING_VALUE_LIST: "text_mapping_value_conf_list",
    WORKFLOW_CONF_ACTION_TAKERS_LIST: "workflow_configuration_action_takers_conf_list",
    DELIVERY_MODULE_TEAM_MEMBERS: "delivery_module_team_members_conf_list",
    DELIVERY_MODULE_CHALLENGES: "delivery_module_challenges_conf_list",
    DELIVERY_MODULE_ACTIONS: "delivery_module_actions_conf_list",
    DELIVERY_MODULE_STAKEHOLDERS: "delivery_module_stakeholders_conf_list",
    BULLET_ELEMENTS_CONFIG: "bullet_elements_conf_list",
    DOCSADDA_MANAGE_LIST_CONFIG: "docs_adaa2_manager_conf_list",
    DOWNLOADED_REPORTS_CONF_LIST: "downloaded_reports_conf_list",
    DATA_ENTRY_ATTACHMENT_LIST: "data_entry_attachment_list_conf_list",
    ENTITY_MIGRATION_LIST: "entity_migration_conf_list",
    ENTITY_MIGRATION_OPERATIONS_LIST: "entity_migration_operations_conf_list",
    AUDIT_CRITERIA_CONF_LIST: "audit_criteria_set_conf_list",
    AUDIT_CRITERIA_CLONE_CONF_LIST: "audit_criteria_clone_conf_list",
    NATIONAL_PROJECTS_TARGETS_LIST_CONF_LIST: "national_project_national_targets_list",
    NATIONAL_PROJECTS_MILESTONE_TARGETS_LIST: "national_project_milestone_targets_list",
    NATIONAL_PROJECT_MEMBERS_CONF_LIST: "national_project_member_conf_list",
    AUDIT_CRITERIA_SELECT_FROM_EXISTING_CONF_LIST: "audit_criteria_select_from_existing_conf_list",
    AUDIT_PLAN_CONF_LIST: "audit_plan_conf_list",
    WORKFLOW_COMMENT_HISTORY_CONF_LIST: "workflow_comment_history_conf_list",
    KPI_HOMEPAGE_PERF_AGG_CONF_LIST: "kpi_homepage_perf_agg_conf_list",
    PMO_HOMEPAGE_PERF_AGG_CONF_LIST: "pmo_homepage_perf_agg_conf_list",
    OTHER_ENTITIES_CONF_LIST: "other_entities_conf_list",
    TRANSFORMATIONAL_PROJECTS_CYCLE: "tp_cycles_conf_list",
    NATIONAL_SECTORS: "national_sectors_conf_list",
    TRANSFORMATIONAL_TARGETS: "transformational_target_conf_list",
    SUB_PROJECT_MILESTONE_CONF_LIST: "sub_project_milestones_conf_list",
    MILESTONE_MEMBERS_CONF_LIST: "milestone_member_conf_list",
    PROJECT_MILESTONES_CONF_LIST: "project_milestones_conf_list",
    GOV_SECTORS_CONF_LIST: "government_sectors_conf_list",
    GOV_ENTITY_PILLARS_CONF: "government_entity_pillars_conf_list",
    GOV_ENTITY_PILLARS_STEP_CONF: "government_entity_pillars_step_conf_list",
    GOV_ENTITY_PILLARS_AREA_OF_FOCUS_CONF: "government_entity_pillars_area_of_focus_conf_list",
    METRICS_MONITORING: "metrics_monitoring_conf_list",
    PROJECT_MILESTONE_STEP_CONF_LIST: "milestones_step_conf_list",
    NATIONAL_PROJECTS_CONF_LIST: "national_projects_conf_list",
    GOV_MEASUREMENT_SCOPE_CONF_LIST: "gov_measurement_scope_conf_list",
    GOVERNMENT_SCOPES_CONF_LIST: "government_scops_conf_list",
    EKPI_DATA_INPUT_CONF_LIST: "ekpi_data_input_conf_list",
    PROJECT_AGREEMENT_CONF_LIST: "project_agreement_conf_list",
    AGM_PROJECTS_CONF_LIST: "agm_projects_conf_list",
    AGM_PROJECT_MILESTONES_CONF_LIST: "agm_project_milestones_conf_list",
    DIMENSIONS_SYNC_LOGS: "dimensions_sync_logs_conf_list",
    DIMENSIONS_SYNC_LOGS_DETAILS: "dimensions_sync_logs_details_conf_list",
    DIMENSIONS_SYNC_LOGS_VALUE_DETAILS: "dimensions_sync_logs_value_details_conf_list",
    DATA_ENTRY_TIMELINES_CONF_LIST: "data_cycle_config_list",
  };

  static VALIDATORS_CONF_KEY = {
    VALIDATION_NKPI: "nkpi",
    VALIDATION_UAEKPI: "uae_kpi",
    VALIDATION_EKPI: "ekpi",
    VALIDATION_SKPI: "skpi",
    VALIDATION_MOKPI: "mokpi",
    VALIDATION_MTKPI: "mtkpi",
    VALIDATION_OPMKPI: "opm_kpi",
    VALIDATION_SRVKPI: "srv_kpi",
    VALIDATION_NTKPI: "ntkpi",
    VALIDATION_DTKPI: "dtkpi",
    VALIDATION_GSKPI: "gskpi",
    VALIDATION_ATTACHMENTS: "attachment",
    VALIDATION_BENCHMARKS: "benchmarks",
    VALIDATION_INITIATIVES: "initiatives",
    VALIDATION_ENTITY: "entity",
    VALIDATION_OBJECTIVES: "objectives",
    VALIDATION_METRICS: "metrics",
    VALIDATION_ADDRESSES: "address",
    VALIDATION_ACTIVITIES: "activity",
    VALIDATION_ATTRIBUTES: "attributes",
    VALIDATION_ORGUNIT: "org_unit",
    VALIDATION_ORGPEOPLE: "org_people",
    VALIDATION_FORMULA_VALIDATE: "formula_validate",
    VALIDATION_ACCESS_PROFILES: "access_profile",
    VALIDATION_EXEC_TEAM: "exec_team",
    VALIDATION_EXEC_TEAM_MEMBER: "exec_team_member",
    VALIDATION_DIMENSIONS: "dimensions",
    VALIDATION_USERS: "users",
    VALIDATION_PILLARS: "pillar",
    VALIDATION_USERS_ROLES: "users_roles_conf",
    VALIDATION_USERS_GROUPS: "users_groups_conf",
    VALIDATION_USERS_PROFILES: "users_profiles_conf",
    VALIDATION_TASKS: "tasks",
    VALIDATION_RISKS: "risks",
    VALIDATION_HELP_MODAL: "help_modal_conf_form",
    VALIDATION_SERVICES: "services_conf_form",
    VALIDATION_DATA_CYCLE_AUTOMATION: "data_cycle_automation",
    VALIDATION_MAIN_SERVICE: "main_service_form",
    VALIDATION_VAR_SERVICE: "var_service_form",
    VALIDATION_AUX_SERVICE: "aux_service_form",
    VALIDATION_COMMUNICATION_CENTER: "communication_center",
    VALIDATION_HELPDESK: "helpdesk",
    VALIDATION_DELIVERY_MODULE_TASKS: "delivery_module_tasks",
    VALIDATION_DELIVERY_MODULE_RISKS: "delivery_module_risks",
    VALIDATION_DELIVERY_MODULE_RESOURCES: "delivery_module_resources",
    VALIDATION_DELIVERY_MODULE_RESOURCES_NO_ACTIVITY: "delivery_module_resources_no_activity",
    VALIDATION_DELIVERY_MODULE_INFORMATION: "delivery_module_information",
    VALIDATION_DELIVERY_RESOURCES_FORM: "delivery_module_resources_form",
    VALIDATION_DELIVERY_ATTACHMENTS: "delivery_module_attachments",
    VALIDATION_TEXTMAPPING_VALUES: "text_mapping_values",
    VALIDATION_TEXTMAPPING: "text_mapping",
    VALIDATION_WORKFLOW: "services_conf_workflow",
    VALIDATION_ENABLER_OBJECTIVES: "enabler_objectives",
    VALIDATION_LOOKUP_FORM: "lookup_form_config",
    VALIDATION_LOOKUP_FORM_MANAGMENT: "lookup_form_manegement",
    VALIDATION_FORMULAS_MANAGEMENT: "formulas_management",
    VALIDATION_FAQS: "faqs_mandatory_conf",
    VALIDATION_CATEGORY_FAQS: "faqs_category_mandatory_conf",
    VALIDATION_RULES_MANAGEMENT_TRIGGER: "rules_management_mandatory_trigger",
    VALIDATION_RULES_MANAGEMENT_CALENDAR: "rules_management_mandatory_calendar",
    VALIDATION_RULES_MANDATORY_CHECK_RULE: "integrity_rules_management_mandatory_rule",
    VALIDATION_RULES_VALUE_CHECK_RULE: "integrity_rules_management_value_check_rule",
    VALIDATION_RULES_CONDITIONAL_CHECK_RULE: "integrity_rules_management_conditional_check_rule",
    VALIDATION_RULES_COMPLEX_FILE_CHECK_RULE: "integrity_rules_management_complex_check_rule",
    VALIDATION_RULES_DEPENDENCY_CHECK_RULE: "integrity_rules_management_DEPENDENCY_check_rule",
    VALIDATION_CONTEXT_HELP: "contextual_help_form_config",
    VALIDATION_CONTEXT_HELP_STEP: "contextual_help_form_step_config",
    VALIDATION_WORKFLOW_TRIGGERS_FORM: "workflow_configuration_triggers_form",
    VALIDATION_WORKFLOW_CONFIGURATION_FORM: "workflow_configuration_form",
    VALIDATION_DATA_ENTRY_KPI_FORM: "data_entry_kpi_modal",
    VALIDATION_ATTRIBUTES_TEXT_MAPPING_FORM: "attributes_text_mapping_form",
    VALIDATION_DIMENSIONS_NAME_LIST_FORM: "dimensions_value_name_list_form",
    VALIDATION_DIMENSIONS_GEOLOCATION_LIST_FORM: "dimensions_value_geolocation_list_form",
    VALIDATION_SMART_TOOL_TIPS_FORM: "help_tooltip_form",
    VALIDATION_DOCUMENTS_UPLOAD_FORM: "documents_upload_form",
    VALIDATION_AUDIT_PLAN_FORM: "audit_plan",
    VALIDATION_AUDIT_CRITERIA_FROM: "audit_criteria_form",
    VALIDATION_NATIONAL_SECTOR_FORM: "national_sector_form",
    VALIDATION_NATIONAL_PROJECT_FORM: "national_project_form",
    VALIDATION_NATIONAL_MILESTONE_FORM: "project_milestone_form",
    VALIDATION_GOVERNMENT_DIRECTIONS: "government_direction",
    VALIDATION_GOVERNMENT_SUB_DIRECTIONS: "sub_government_direction",
    VALIDATION_GOVERNMENT_AUX_DIRECTIONS: "aux_government_direction",
    VALIDATION_CYCLES: "cycle",
    VALIDATION_OTHER_ENTITIES: "other_entity_form",
    VALIDATION_LOOKUP: "lookup_form_manegement",
    VALIDATION_ENTITY_REPORTS: "documents_upload_form",
    VALIDATION_NATIONAL_STRATEGY: "national_strategy",
    VALIDATION_TP_CYCLES: "tp_cycles_form",
    VALIDATION_TP_TARGET: "transformational_target_form",
    VALIDATION_TEXT_MAPPINGS: "text_mapping",
    VALIDATION_TEXT_MAPPINGS_VALUES: "text_mapping_values",
    VALIDATION_SUB_PROJECT_MILESTONE: "sub_project_milestones_form",
    VALIDATION_GOV_SECTORS: "government_sectors_form",
    NATIONAL_PROJECT_MEMBER: "national_project_member",
    NATIONAL_TARGET_SUCCESS_STORY_FORM: "national_target_success_story_form",
    NATIONAL_TARGET_CHALLENGE_ANALYSIS_FORM: "national_target_challenge_analysis_form",
    VALIDATION_PROJECT_MILESTONE_STEP_FORM: "project_milestone_step_form",
    VALIDATION_GOVERNMENT_SCOPES_FORM: "government_scops_form",
    PROJECT_AGREEMENT_FORM: "project_agreement_form",
    VALIDATION_IMPROVEMENT_PLAN_FORM: "improvement_plan_form",
  };

  static CONSTANT_ATTACHTYPE = 1;

  static TICKET_SECTIONS = {
    USERS: 186,
    USER_ROLES: 187,
    USER_GROUPS: 188,
    ENTITIES: 189,
    METRICS: 190,
    DIMENSIONS: 191,
    ATTRIBUTES: 192,
    OBJECTIVES: 193,
    INITIATIVES: 194,
    ACTIVITIES: 195,
    GOVERNMENT_DIRECTIONS: 197,
    SERVICES: 198,
    CYCLES: 199,
    PILLARS: 200,
    MANDATORY_FIELDS: 201,
    DATA_ENTRY: 202,
    PROVISIONING: 204,
    FORMULAS: 205,
    SUPPORT_CENTER: 206,
    DATA_CYCLES: 207,
    NOTIFICATIONS: 208,
    USER_PROFILE: 231,
    NKPI: 232,
    DKPI: 233,
    SKPI: 234,
    MOKPI: 495,
    MTKPI: 496,
    OPM: 235,
    EKPI: 236,
    NTKPI: 2055,
    DTKPI: 2056,
    PLAN_EDITOR: 238,
    COMMUNICATIONS_CENTER: 266,
    DATA_ENTRY_METTRICS: 268,
    NAVIGATION: 269,
    WORKFLOW_CONFIGURATION: 270,
    WORKFLOW_MONITORING: 480,
    WORKFLOW_TRIGGER: 481,
    NATIONAL_PROJECT: 497,
    NATIONAL_PROJECT_MILESTONE: 498,
    OTHER_ENTITIES: 103705,
    IMPORT_EXPORT_KPI: 103706,
    TEXT_MAPPING: 103707,
    NATIONAL_PROJECT_AGREEMENT: 103708,
    NATIONAL_PROJECT_SECTOR: 103709,
    TRANSFORMATIONAL_TARGETS: 103710,
    SRVKPI: 103711,
    GOV_SECTORS: 103712,
    GOV_SCOPES: 103713,
    AGM_PROJECT: 103714,
    ENTITY_REPORTS: 103715,
    FAQS: 103716,
  };

  static TICKET_ITEMS = {
    LIST: 209,
    FORM: 210,
    CARD: 211,
  };

  static ITEM_TYPES_CALCULATION = {
    OBJECTIVE: 42,
    INITIATIVE: 43,
    ACTIVITY: 44,
    SERVICE: 47,
    NKPI: 37,
    SKPI: 38,
    OKPI: 39,
    DKPI: 40,
    EKPI: 41,
    SRVKPI: 82,
  };

  static FORM_SUBMIT_ACTION = {
    APPROVED: 3,
    REJECTED: 4,
  };

  static VISION_PERFORMANCE = {
    NKPI: 378,
    DKPI: 4008,
    NTKPI: 558,
    DTKPI: 568,
    MOKPI: 538,
    MTKPI: 548,
  };

  static CONSTANT_PMO_ID = 1;
  static CONSTANT_MEASUREMENT_UNIT = 2;
  static CONSTANT_FREQUENCY = 3;
  static CONSTANT_BENCHTYPE = 4;
  static CONSTANT_MEASUREMENT_NUMBER = 6;
  static CONSTANT_YTP = 6;
  static CONSTANT_MEASUREMENT_CURRENCY_01 = 7;
  static CONSTANT_MEASUREMENT_PERCENTAGE_01 = 8;
  static CONSTANT_ITEMTYPE = 8;
  static CONSTANT_KPITYPE_PROP = 9;
  static CONSTANT_MEASUREMENT_RANK = 9;
  static CONSTANT_MEASUREMENT_TEXT_MAPPING = 10;
  static CONSTANT_SERVICETYPES_TYPE = 10;
  static FREQUENCY_MONTHLY = 12;
  static FREQUENCY_QUARTERLY = 13;
  static FREQUENCY_SEMIANNUAL = 14;
  static CONSTANT_EXEC_TEAM_MEMBER_TITLE_TYPE = 14;
  static FREQUENCY_ANNUAL = 15;
  static CONSTANT_ENTITY_TYPE = 16;
  static FREQUENCY_EVERY_TWO_YEARS = 16;
  static CONSTANT_FORMULA_LLK = 17;
  static FREQUENCY_EVERY_THREE_YEARS = 17;
  static FREQUENCY_EVERY_FOUR_YEARS = 18;
  static FREQUENCY_EVERY_FIVE_YEARS = 19;
  static CONSTANT_CLASSIFICATION_TYPE = 21;
  static CONSTANT_TREND = 22;
  static CONSTANT_OBJECT_TYPE = 25;
  static CONSTANT_PERSPECTIVE_TYPE = 26;
  static CONSTANT_MEASUREMENT_UNIT_METRICS = 27;
  static CONSTANT_SERVICE_AUX_VAR_TYPE = 29;
  static CONSTANT_SERVICE_AUX_VAR_CLASSIFICATION = 30;
  static CONSTANT_MEASUREMENT_UNIT_ATTRIBUTES = 31;
  static CONSTANT_ACTIONTYPES = 32;
  static CONSTANT_WFTYPES = 33;
  static CONSTANT_ACTUALS = 34;
  static CONSTANT_NKPITYPE = 37;
  static CONSTANT_STATUS = 37;
  static CONSTANT_SKPITYPE = 38;
  static CONSTANT_TICKET_TYPE = 38;
  static CONSTANT_HELP_DESK_TICKET_TYPE = 38;
  static CONSTANT_OPM = 39;
  static CONSTANT_PERIORITY = 39;
  static CONSTANT_TICKET_PRIORITY = 39;
  static CONSTANT_UAEKPI = 40;
  static CONSTANT_TICKET_SECTION = 40;
  static CONSTANT_EKPI = 41;
  static CONSTANT_ITEMS = 41;
  static CONSTANT_TICKET_ITEM = 41;
  static CONSTANT_DATA_INPUT_TABLE = 42;
  static CONSTANT_OBJECTIVETYPE = 42;
  static CONSTANT_INITIATIVE = 43;
  static CONSTANT_ACTIVITYTYPE = 44;
  static CONSTANT_GOVDIRECTION = 45;
  static CONSTANT_TICKET_TYPE_PMO = 45;
  static CONSTANT_SUBGOVDIRECTION = 46;
  static CONSTANT_SERVICE = 47;
  static CONSTANT_TASKTYPE = 48;
  static CONSTANT_ITEMTYPES = 50;
  static CONSTANT_MAINSERVICE = 50;
  static CONSTANT_VARSERVICE = 51;
  static CONSTANT_AUXSERVICE = 52;
  static CONSTANT_MOKPITYPE = 53;
  static CONSTANT_MTKPITYPE = 54;
  static CONSTANT_NTKPITYPE = 55;
  static CONSTANT_DTKPITYPE = 56;
  static CONSTANT_RULESET_SEVERITY = 55;
  static CONSTANT_RULESET_SCOPE = 57;
  static CONSTANT_SECURITYQUESTIONS = 62;
  static CONSTANT_VALIDATOR_FORM = 66;
  static CONSTANT_GRACE_PERIOD_DURATIONS = 66;
  static CONSTANT_VALIDATOR_CONF_LIST = 67;
  static CONSTANT_RULESET_EXECUTION_RESULT = 69;
  static CONSTANT_YEARS = 70;
  static CONSTANT_FORMULA = 71;
  static CONSTANT_COUNTRIES = 71;
  static CONSTANT_NOFORMULA = 72;
  static CONSTANT_FORMULAWTARGETS = 73;
  static CONSTANT_RULESET_TYPE = 73;
  static CONSTANT_KHADAMATI_IMPORT_STATUS = 81;
  static CONSTANT_SRVKPI = 82;
  static CONSTANT_METRICS_TYPE = 83;
  static CONSTANT_DIMENSIONS_TYPE = 85;
  static CONSTANT_PILLARS_TYPE = 86;
  static CONSTANT_CYCLE_STATUS = 92;
  static CONSTANT_REPORT_OUTPUT_FORMAT = 93;
  static CONSTANT_REPORT_LANGUAGE = 94;
  static CONSTANT_CYCLES = 95;
  static CONSTANT_KHADAMATI_DETAILS_ACTIONS = 108;
  static CONSTANT_USERS_TYPE = 115;

  static CONSTANT_DIMENSIONS_VALUETYPE_NAME_LIST = 122;
  static CONSTANT_DIMENSIONS_VALUETYPE_GEO_LOCATION = 123;
  static CONSTANT_DIMENSIONS_VALUETYPE_SERVICES_LIST = 124;

  static CONSTANT_EMPLOYEE_PULSE_DASHBOARD = 129;
  static FRONT_END_TOGGLES = 129;

  static CONSTANT_OBJECTIVE_TYPE_PROP_ID = 132;
  static CONSTANT_ATTRIBUTES_DATATYPE_FREE_TEXT = 136;
  static CONSTANT_GOV_METRIC_AGG_METHOD = 137;
  static CONSTANT_ATTRIBUTES_DATATYPE_DATE = 137;
  static CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ENT = 138;
  static CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ORG = 139;

  //TREND
  static CONSTANT_TREND_INCREASING = 140;
  static CONSTANT_TREND_DECREASING = 141;
  static CONSTANT_TREND_ONTARGET = 142;
  static CONSTANT_TREND_BOUNDED = 143;
  //END OF TREND

  static CONSTANT_KPI_TARGET_TYPE = 142;

  static CONSTANT_PUBLISHED_STATUS = 146;
  static CONSTANT_PUBLISHED_STATE = 146;
  static CONSTANT_METRICS_NUMBER = 146;
  static CONSTANT_TARGET_CATEGORY = 147;
  static CONSTANT_METRICS_CURRENCY = 147;
  static CONSTANT_METRICS_PERCENTAGE = 148;
  static CONSTANT_MONTHS = 149;
  static CONSTANT_METRICS_RANK = 149;
  static CONSTANT_METRICS_TEXT_MAPPING = 150;
  static CONSTANT_METRICS_FREE_TEXT = 151;
  static CONSTANT_LEADERSHIP_LEVEL = 151;
  static CONSTANT_ATTRIBUTES_DATATYPE_TEXT_MAPPING = 152;
  static CONSTANT_CHALLENGE_ASSESSMENT = 152;
  static CONSTANT_ATTRIBUTES_DATATYPE_UNFOLD_LOV = 152;
  static CONSTANT_ATTRIBUTES_DATATYPE_MULTILINE_TEXT = 153;
  static CONSTANT_WF_ACTION_CATEGORY = 153;
  static CONSTANT_TP_PMO_DECISION = 156;
  static CONSTANT_MAIN_OUTCOME_TYPE_PROP = 157;

  static CONSTANT_ENTITY_SIZE = 159;
  static CONSTANT_NATIONAL_PROJECTS = 160;
  static CONSTANT_NATIONAL_PROJECTS_MILESTONE = 161;
  static CONSTANT_SDG_GOV_DIR_ID = 195;
  static CONSTANT_WE_THE_UAE_2031_SUB_GOV_ID = 196;
  static CONSTANT_DATA_INPUT_ENTITY = 218;
  static CONSTANT_MEASUREMENT_CURRENCY = 292;
  static CONSTANT_MEASUREMENT_PERCENTAGE = 293;
  static CONSTANT_USER_GROUP_TYPE_HELPDESK = 248;
  static CONSTANT_EXTERNAL_ENTITY_TYPE = 500;
  static CONSTANT_ANNUAL_MEETINGS_PROJECT = 590;
  static CONSTANT_ANNUAL_MEETINGS_MILESTONE = 591;
  static GOV_SCORES_SECTOR = 595;
  static GOV_SCORES_PILLAR = 596;
  static GOV_SCORES_SCOPE = 597;
  static GOV_SCORES_ENTITY = 598;
  static CONSTANT_TRANSFORMATIONAL_TARGETS = 750;

  static CONSTANT_STRATEGIC_OBJECTIVE = 4200; //* default
  static CONSTANT_MAIN_OBJECTIVE = 4201;
  static CONSTANT_ENABLER_OBJECTIVE = 4202;
  static CONSTANT_TRANSFORMATIONAL_OBJECTIVE = 4203;

  static CONSTANT_STRATEGIC_PROJECT_INITIATIVETYPE = 4300;
  static CONSTANT_REGULAR_TASK_INITIATIVETYPE = 4301; //* default

  static CONSTANT_STRATEGIC_ACTIVITYTYPE = 4400;
  static CONSTANT_OPERATIONAL_ACTIVITYTYPE = 4401; //* default

  static CONSTANT_TRANSFORMATIONAL_AGREEMENT = 103055;
  static CONSTANT_NTKPI_CHALLENGE_ANALYSIS = 102762;
  static CONSTANT_NTKPI_SUCCESS_STORY = 102763;
  static CONSTANT_PMO_DECISION_OTHERS_ID = 102904;
  static CONSTANT_TP_AGREEMENT_READY_TO_PUBLISH = 102911;

  static CONSTANT_DATA_INPUT = {
    PMO: 220,
    ENABLER: 219,
    ENTITY: 218,
  };

  static NTKPI_CARD_PROPS = {
    entityLeaderDecision: 150,
    leadershipLevel: 151,
    assessment: 152,
  };

  static CONSTANT_AUDIT_ACTIONS_OPTIONS = {
    CREATE: 74,
    UPDATE: 76,
    DELETE: 75,
    GET: 77,
    APPROVAL: 418,
    IMPORT: 416,
    EXPORT: 417,
    PUBLIC_API: 419,
    REJECTION: 491,
    LOGIN: 420,
    PUBLISH: 103056,
  };

  static OperationTypeWFMonitoring: ValueText[] = [
    {
      value: "",
      text: "common.form.label.none",
    },
    {
      value: Constants.CONSTANT_WORKFLOW_WFTYPES.APPROVAL,
      text: "workflow.approval",
    },
    {
      value: Constants.CONSTANT_WORKFLOW_WFTYPES.REVIEW,
      text: "workflow.review",
    },
  ];

  static FORM_FIELD_CONSTRAINTS = {
    CHAR_MAXLENGTH: 500,
    DESC_MAXLENGTH: 3000,
    DOC_MAX_SIZE: 5000000,
  };

  static MEASUREMENT_UNITS = [
    {
      id: Constants.CONSTANT_MEASUREMENT_NUMBER, //number
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_MEASUREMENT_CURRENCY, //currency
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_MEASUREMENT_PERCENTAGE, //percentage
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: 0,
      max: 100,
    },
    {
      id: Constants.CONSTANT_MEASUREMENT_RANK, //rank
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: 0,
      max: 0,
    },
    {
      id: Constants.CONSTANT_MEASUREMENT_TEXT_MAPPING,
      input_type: "drop",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_ATTRIBUTES_DATATYPE_TEXT_MAPPING, //text mapping
      input_type: "drop",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_ATTRIBUTES_DATATYPE_FREE_TEXT, //free text
      input_type: "text",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_ATTRIBUTES_DATATYPE_DATE, //DATE
      input_type: "datepicker",
      objField: "valueDate",
      flgUseLang: false,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ENT, //PERSON (ENTITY USER)
      input_type: "drop",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_ATTRIBUTES_DATATYPE_FILL_LOV_ORG, //ORGANIZATION UNIT
      input_type: "drop",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_ATTRIBUTES_DATATYPE_MULTILINE_TEXT, //MULTILINE TEXT
      input_type: "textarea",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_METRICS_NUMBER, //number
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_METRICS_CURRENCY, //currency
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_METRICS_PERCENTAGE, //percentage
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: 0,
      max: 100,
    },
    {
      id: Constants.CONSTANT_METRICS_RANK, //rank
      input_type: "number",
      objField: "valueNumber",
      flgUseLang: false,
      min: 0,
      max: 0,
    },
    {
      id: Constants.CONSTANT_METRICS_TEXT_MAPPING, //text mapping
      input_type: "drop",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
    {
      id: Constants.CONSTANT_METRICS_FREE_TEXT, //free text
      input_type: "text",
      objField: "valueText",
      flgUseLang: true,
      min: undefined,
      max: undefined,
    },
  ];

  static ITEM_TYPE_ROUTES = [
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.DIMENSIONS,
      route: "/console/dimensions/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.DELIVERY_MODULE_ACCESS,
      route: "/console/delivery-module/",
    },
    {
      itemTypeId: this.CONSTANT_USERS_TYPE,
      route: "/console/users/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.ENTITIES,
      route: "/console/entities/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.USER_ROLES,
      route: "/console/users-roles/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.USER_GROUPS,
      route: "/console/users-groups/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.GOVERNMENT_DIRECTIONS,
      route: "/console/directions/",
    },
    {
      itemTypeId: this.CONSTANT_SUBGOVDIRECTION,
      route: "/console/directions/",
    },
    {
      itemTypeId: this.KPI_TYPE.NKPI,
      route: "/console/kpi/nkpi/",
    },
    {
      itemTypeId: this.KPI_TYPE.SKPI,
      route: "/console/kpi/skpi/",
    },
    {
      itemTypeId: this.KPI_TYPE.OPM,
      route: "/console/kpi/opm/",
    },
    {
      itemTypeId: this.KPI_TYPE.UAE,
      route: "/console/kpi/uae/",
    },
    {
      itemTypeId: this.KPI_TYPE.EKPI,
      route: "/console/kpi/ekpi/",
    },
    {
      itemTypeId: this.KPI_TYPE.SRVKPI,
      route: "/console/kpi/srvkpi/",
    },
    {
      itemTypeId: this.CONSTANT_NTKPITYPE,
      route: "/console/kpi/ntkpi/",
    },
    {
      itemTypeId: this.KPI_TYPE.GSKPI,
      route: "/console/kpi/gskpi",
    },
    {
      itemTypeId: this.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
      route: "/console/initiatives/regular-tasks/",
    },
    {
      itemTypeId: this.CONSTANT_REGULAR_TASK_INITIATIVETYPE,
      route: "/console/initiatives/strategic-projects/",
    },
    {
      itemTypeId: this.CONSTANT_MAINSERVICE,
      route: "/console/services/main-service/",
    },
    {
      itemTypeId: this.CONSTANT_VARSERVICE,
      route: "/console/services/var-service/",
    },
    {
      itemTypeId: this.CONSTANT_AUXSERVICE,
      route: "/console/services/aux-service/",
    },
    {
      itemTypeId: this.CONSTANT_OPERATIONAL_ACTIVITYTYPE,
      route: "/console/activities/operational/",
    },
    {
      itemTypeId: this.CONSTANT_STRATEGIC_ACTIVITYTYPE,
      route: "/console/activity/strategic/",
    },
    {
      itemTypeId: this.CONSTANT_ENABLER_OBJECTIVE,
      route: "/console/pmo-objectives/enabler-objective/",
    },
    {
      itemTypeId: this.CONSTANT_TRANSFORMATIONAL_OBJECTIVE,
      route: "/console/pmo-objectives/transformational-objective/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.MAIN_OBJECTIVE,
      route: "/console/objectives/main-objective/",
    },
    {
      itemTypeId: this.CONSTANT_STRATEGIC_OBJECTIVE,
      route: "/console/objectives/strategic-objective/",
    },
    {
      itemTypeId: this.CONSTANT_CYCLES,
      route: "/console/cycles/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.SERVICES_KHADAMATI,
      route: "/console/services/main-service/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.OBJECTIVES,
      route: "/console/objectives/strategic-objective/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.PILLARS,
      route: "/console/pillars/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.INITIATIVES,
      route: "/console/initiatives/regular-tasks/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.EXECUTIVE_TEAMS,
      route: "/console/executive-teams/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.ACTIVITIES,
      route: "/console/activities/operational/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.STRATEGIC_ACTIVITIES,
      route: "/console/activities/strategic/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_CYCLES,
      route: "/console/annual-meetings-projects/cycles/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.ANNUAL_MEETINGS_PROJECTS_MILESTONES,
      route: "/console/annual-meetings-projects/milestones/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.EXTENDEND_ATTRIBUTES,
      route: "/console/attributes/",
    },
    {
      itemTypeId: this.CONSTANT_DTKPITYPE,
      route: "/console/kpi/dtkpi/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT,
      route: "/console/initiatives/strategic-projects/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.METRICS,
      route: "/console/metrics/",
    },
    {
      itemTypeId: this.CONSTANT_MTKPITYPE,
      route: "/console/kpi/mtkpi/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.OTHER_ENTITIES,
      route: "/console/other-entities/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.NATIONAL_PROJECTS,
      route: "/national-projects-dashboard/projects/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_TARGETS,
      route: "/console/national-project/transformational-targets/",
    },
    {
      itemTypeId: this.CONSTANT_MOKPITYPE,
      route: "/console/kpi/mokpi/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.NATIONAL_PROJECT_MILESTONES,
      route: "/national-projects-dashboard/milestones/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.ENTITY_ADDRESSES,
      route: "/console/provisioning/addresses",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.ORG_UNIT,
      route: "/console/provisioning/organization-unit",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.FORMULAS,
      route: "/console/formulas/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.TP_CYCLES,
      route: "/console/national-project/cycles/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.TEXTMAPPING,
      route: "/console/text-mapping/",
    },
    {
      itemTypeId: this.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_PROJECT_AGREEMENT,
    },
  ];
}
