<div class="modal-header">
    <h4 class="modal-title fw-bold">{{ "tooltips.filter" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="filterForm" class="row">
        @for (item of tableColumns; track item) {
            <div class="col-sm-12 col-xs-12" [ngClass]="tableColumns.length === 1 ? 'col-md-12' : 'col-md-6'">
                @if (item.filterType === filterType.textFields) {
                    <adaa-form-input
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                        inputType="text"
                    />
                } @else if (item.filterType === filterType.dropdownSingle) {
                    <adaa-form-dropdown
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                        [options]="item.dropdown"
                        [clearable]="true"
                        [searchable]="true"
                    />
                } @else if (item.filterType === filterType.dropdownMulti) {
                    <adaa-form-dropdown-multi
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                        [options]="item.dropdown"
                        [clearable]="true"
                        [searchable]="true"
                        (inputChanges)="dropdownChanged($event, item)"
                    />
                } @else if (item.filterType === filterType.dateRange) {
                    <adaa-form-input-date-range
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                    />
                } @else if (item.filterType === filterType.valueRange) {
                    <adaa-form-input-range
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                    />
                } @else if (item.filterType === filterType.dropdownTree) {
                    <adaa-form-dropdown-tree
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                        [options]="item.dropdown"
                        (inputChanges)="dropdownChanged($event, item)"
                    />
                } @else if (item.filterType === filterType.YearRange) {
                    <adaa-form-input-year-range
                        [label]="adaaHelper.getItemValueByToken(item, 'name')"
                        [controlName]="item.fieldName"
                        [formControlName]="item.fieldName"
                    />
                }
            </div>
        }
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">
        {{ "common.form.button.cancel" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="reset()">
        {{ "common.form.button.reset" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="apply()">
        {{ "common.form.button.apply" | translate }}
    </button>
</div>
