import { HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { FileSaverService } from "ngx-filesaver";

import { MainResponseModel } from "../models";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class FilesApiService {
  private _mainService = inject(MainService);
  private _fileSaverService = inject(FileSaverService);

  public download(fileId: number, showLoader = false, downloadUrl: string = "file/download") {
    const url = `${downloadUrl}/${fileId}`;
    const headers = new HttpHeaders();

    headers.append("Content-Type", "multipart/form-data");
    headers.append(
      "Accept",
      "application/json, application/octet-stream, image/svg+xml, image/bmp, image/gif, image/vnd.microsoft.icon, image/jpeg, image/png, image/tiff, image/webp"
    );

    return this._mainService.sendRequest<Blob>({
      method: "GET",
      url: url,
      headers,
      responseType: "blob",
      loader: showLoader,
    });
  }

  public save(fileId: number, fileName: string, showLoader = false, downloadUrl: string = "file/download") {
    this.download(fileId, showLoader, downloadUrl).subscribe({
      next: async (blob) => {
        this._fileSaverService.save(blob, fileName);
      },
    });
  }

  public upload(file: File, showLoader = false, attachUrl: string = "file/upload") {
    const headers = new HttpHeaders();

    headers.append("Content-Type", "multipart/form-data");
    headers.append(
      "Accept",
      "application/json, application/octet-stream, image/svg+xml, image/bmp, image/gif, image/vnd.microsoft.icon, image/jpeg, image/png, image/tiff, image/webp"
    );

    const data = new FormData();

    data.append("file", file);

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url: attachUrl,
      data,
      headers,
      loader: showLoader,
    });
  }

  public deleteFile(fileId: number) {
    const url = `file/delete`;
    const data = { id: fileId };

    return this._mainService.sendRequest<MainResponseModel<null>>({
      method: "DELETE",
      url,
      data,
    });
  }
}
