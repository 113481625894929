import { CommonModule } from "@angular/common";
import { Component, inject, signal, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { TranslateTokenPipe } from "../../../../core/pipes";
import { AdaaHelper } from "../../../../core/utils";
import { Constants } from "../../../constants/constants";
import { NotificationConfig, Recipient } from "../../../models/notifications-config.model";
import { LanguageService } from "../../../services";
import { DirectivesApiService } from "../../../services/directives-api.service";
import { NotificationsApiService } from "../../../services/notifications-api.service";

@Component({
  selector: "adaa-tp-directions-popup",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, CommonModule, TranslateTokenPipe],
  template: `
    <ng-template #modal let-modal>
      <div class="modal-header bg-white">
        <div class="w-100 d-flex justify-content-center">
          <h4 class="fw-bold w-100 text-center py-1">{{ "directions.title" | translate }}</h4>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="model" class="w-100 p-3" (ngSubmit)="submit()">
          <div class="container">
            <div class="row g-3 justify-content-center" [dir]="languageService.direction()">
              @for (notif of notificationsConfig(); track $index) {
                <div class="col-6 d-flex justify-content-center">
                  <button
                    class="btn fw-bold shadow-sm px-3 py-2 w-100 d-flex justify-content-center align-items-center rounded-3 border"
                    [ngClass]="{
                      'bg-primary text-white border-primary': selectedNotifId() === notif.id,
                      'bg-white text-secondary border-secondary-subtle': selectedNotifId() !== notif.id,
                    }"
                    (click)="toggleExtendedForm(notif)"
                    type="button"
                  >
                    <span class="text-truncate">{{ notif | translateToken: "name" }}</span>
                  </button>
                </div>
              }
            </div>

            <!-- Extended Section -->
            @if (selectedNotifId()) {
              <div class="mt-4 p-3 border rounded bg-light text-center">
                <div class="d-flex justify-content-center align-items-center">
                  <span class="text-primary fw-bold px-2">{{ "communication.to" | translate }}</span>
                </div>
                <hr class="mt-1 mb-2 w-50 mx-auto" />
                <div class="mt-2">
                  @for (to of to(); track $index) {
                    <button class="btn btn-secondary mx-1" disabled>{{ to | translateToken: "name" }}</button>
                  }
                </div>
              </div>

              <div class="mt-4 p-3 border rounded bg-light text-center">
                <div class="d-flex justify-content-center align-items-center">
                  <span class="text-primary fw-bold px-2">{{ "communication.cc" | translate }}</span>
                </div>
                <hr class="mt-1 mb-2 w-50 mx-auto" />
                <div class="mt-2">
                  @for (cc of cc(); track $index) {
                    <button class="btn btn-secondary mx-1" disabled>{{ cc | translateToken: "name" }}</button>
                  }
                </div>
              </div>

              <div class="mt-4 p-3 border rounded bg-light text-center">
                <div class="d-flex justify-content-center align-items-center">
                  <span class="text-primary fw-bold px-2">{{ "communication.message" | translate }}</span>
                </div>
                <hr class="mt-1 mb-2 w-50 mx-auto" />
                <div class="mt-2" [innerHTML]="content()"></div>
              </div>

              <div class="d-flex w-100 justify-content-center py-2">
                <button type="button" class="btn btn-primary px-4 py-2 mx-2" (click)="modal.dismiss()">
                  {{ "common.form.button.close" | translate }}
                </button>

                <button type="submit" class="btn btn-primary px-4 py-2 mx-2">
                  {{ "common.form.button.send" | translate }}
                </button>
              </div>
            }
          </div>
        </form>
      </div>
    </ng-template>
  `,
})
export class TpDirectionsPopupComponent {
  readonly languageService = inject(LanguageService);
  private readonly _modalService = inject(NgbModal);
  private readonly _notificationService = inject(NotificationsApiService);
  private readonly _directivesApiService = inject(DirectivesApiService);
  private readonly _toastrService = inject(ToastrService);
  private readonly _translateService = inject(TranslateService);

  @ViewChild("modal", { static: true }) formModal!: TemplateRef<unknown>;
  modalRef: NgbModalRef;

  id: number;
  itemType: number;
  Constants = Constants;
  model = new FormGroup({});

  notificationsConfig = signal<NotificationConfig[]>([]);
  selectedNotifId = signal<number | null>(null);
  selectedNotification = signal<NotificationConfig | null>(null);
  to = signal<Recipient[]>([]);
  cc = signal<Recipient[]>([]);
  content = signal<string>("");

  public open(id: number, itemType: number) {
    this.id = id;
    this.itemType = itemType;
    this._loadNotifications();
    this.modalRef = this._modalService.open(this.formModal, { centered: true, size: "lg" });
  }

  private _loadNotifications() {
    this._notificationService
      .getAllNotificatoins({ searchFields: { searchFields: { category: { value: 8 } } } })
      .subscribe({
        next: (response) => {
          if (!response.inError) {
            this.notificationsConfig.set(response.responseData);
          }
        },
      });
  }

  public toggleExtendedForm(notif: NotificationConfig) {
    const isAlreadySelected = this.selectedNotifId() === notif.id;
    this.selectedNotifId.set(isAlreadySelected ? null : notif.id);
    this.selectedNotification.set(isAlreadySelected ? null : notif);

    if (!isAlreadySelected) {
      this._directivesApiService
        .getContent({ notificationId: notif.id, itemId: this.id, itemType: this.itemType })
        .subscribe({
          next: (response) => {
            if (!response.inError) {
              this.to.set(response.responseData?.recipients?.to ?? []);
              this.cc.set(response.responseData?.recipients?.cc ?? []);
              this.content.set(response.responseData?.content ?? "");
            }
          },
        });
    }
  }

  public submit() {
    this._directivesApiService
      .sendNotification({
        entityId: AdaaHelper.entity.id,
        itemId: this.id,
        itemType: this.itemType,
        notificationId: this.selectedNotifId() ?? 0,
      })
      .subscribe({
        next: (response) => {
          if (!response.inError) {
            this._toastrService.success(this._translateService.instant("directive.send_success"));
            this.modalRef.close();
          } else {
            this.modalRef.dismiss();
          }
        },
      });
  }
}
