import { Component, computed, EventEmitter, inject, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

import { useNewDS } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { LanguageService, UserService } from "../../../../../../services";
import type { ScreenSectionConfigType } from "../../wf-difference.types";

@Component({
  selector: "adaa-normal-diff-view",
  standalone: true,
  imports: [TranslateModule],
  styleUrl: "../styles.scss",
  template: `
    @if (!showDIffOnly() || isNew() || hasDiff()) {
      @if (canView() && specialCanViewCondition()) {
        @let conf = config();

        <section class="row d-flex align-content-center" [dir]="languageService.direction()">
          <div
            class="p-2 border border-white tbl-col rounded-start-1 d-flex align-items-center fs-6"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
            [dir]="conf.overrideLabelDir ? 'ltr' : languageService.direction()"
            [class.justify-content-end]="languageService.current() === 'ae' && conf.overrideLabelDir"
          >
            {{ label() | translate }}
          </div>

          <div
            class="p-2 border border-white tbl-col d-flex align-items-center"
            [class.rounded-end-1]="showOnlyNew()"
            [class.col-6]="showOnlyNew()"
            [class.col-4]="!showOnlyNew()"
            [class.tbl-diff]="isNew() || hasDiff() || isRemoved()"
          >
            @if (conf.isClickable) {
              <span
                role="button"
                class="btn btn-link btn-info txt-col p-0 text-left"
                [dir]="languageService.direction()"
                (click)="clicked.emit(newData()?.[conf.field])"
              >
                @if (!conf.showMultipleLines) {
                  {{ formattedNewData() | translate }}
                } @else {
                  <span [innerHTML]="formattedNewData()" [style.white-space]="'pre-line'"></span>
                }
              </span>
            } @else {
              @if (!conf.showMultipleLines) {
                {{ formattedNewData() | translate }}
              } @else {
                <span [innerHTML]="formattedNewData()" [style.white-space]="'pre-line'"></span>
              }

              @if (checkIfIsSpecialString(formattedNewData())) {
                <div [style.backgroundColor]="formattedNewData()" class="color-box"></div>
              }
            }
          </div>

          @if (!showOnlyNew()) {
            <div
              class="col-4 p-2 border border-white tbl-col rounded-end-1 d-flex align-items-center"
              [class.tbl-diff]="hasDiff() || isRemoved()"
            >
              @if (conf.isClickable) {
                <span
                  role="button"
                  class="btn btn-link btn-info txt-col p-0 text-left"
                  (click)="clicked.emit(oldData()?.[conf.field])"
                >
                  @if (!conf.showMultipleLines) {
                    {{ formattedOldData() | translate }}
                  } @else {
                    <span [innerHTML]="formattedOldData()" [style.white-space]="'pre-line'"></span>
                  }
                </span>
              } @else {
                @if (!conf.showMultipleLines) {
                  {{ formattedOldData() | translate }}
                } @else {
                  <span [innerHTML]="formattedOldData()" [style.white-space]="'pre-line'"></span>
                }
                @if (checkIfIsSpecialString(formattedOldData())) {
                  <div [style.backgroundColor]="formattedOldData()" class="color-box"></div>
                }
              }
            </div>
          }
        </section>
      }
    }
  `,
})
export class NormalDiffViewComponent {
  @Output() clicked = new EventEmitter<Record<string, unknown>>();

  readonly languageService = inject(LanguageService);
  private readonly _userService = inject(UserService);

  showOnlyNew = input.required<boolean>();
  config = input.required<ScreenSectionConfigType>();
  isNew = input<boolean>(false);
  newData = input<Record<string, never>>();
  oldData = input<Record<string, never>>();
  showDIffOnly = input<boolean>(false);
  isRemoved = input<boolean>(false);

  label = computed(() => {
    const { label } = this.config();

    if (!label) return "";
    if (typeof label === "string") return label;

    return label({ useNewDS: this.useNewDS(), newData: this.newData(), oldData: this.oldData() } as never);
  });

  formattedNewData = computed(() => {
    const { format, field } = this.config();
    const options = {
      isPmoUser: this._userService.currentUser()?.entityId === Constants.CONSTANT_PMO_ID,
      useNewDS: this.useNewDS(),
    };

    if (!this.newData()) return "";
    if (!format) return this.newData()![field] as string;
    return format(this.newData() as never, options as never) as string;
  });

  formattedOldData = computed(() => {
    const { format, field } = this.config();
    const options = {
      isPmoUser: this._userService.currentUser()?.entityId === Constants.CONSTANT_PMO_ID,
      useNewDS: this.useNewDS(),
    };

    if (!this.oldData()) return "";
    if (!format) return this.oldData()![field] as string;
    return format(this.oldData() as never, options as never) as string;
  });

  hasDiff = computed(() => {
    const { field, hasDiff } = this.config();

    const newDataField = (this.newData() as Record<string, never>)?.[field];
    const oldDataField = (this.oldData() as Record<string, never>)?.[field];

    if (hasDiff) return hasDiff(newDataField, oldDataField);
    return newDataField !== oldDataField;
  });

  useNewDS = computed(() => {
    if (this.newData()) return useNewDS(this.newData()?.planId);
    if (this.oldData()) return useNewDS(this.oldData()?.planId);
    return false;
  });

  canView = computed(() => {
    const { visibleIf } = this.config();
    if (!visibleIf) return true;

    const data = this.newData() ?? this.oldData();
    const options = {
      isPmoUser: this._userService.currentUser()?.entityId === Constants.CONSTANT_PMO_ID,
      useNewDS: this.useNewDS(),
    };

    return visibleIf(data as never, options as never);
  });

  public specialCanViewCondition() {
    const { field } = this.config();

    if (field === "linkedKpiId") {
      return (this.newData() && this.newData()![field]) || (this.oldData() && this.oldData()![field]);
    }

    return true;
  }

  public checkIfIsSpecialString(data: unknown) {
    return typeof data === "string" && data.startsWith("#");
  }
}
