import { inject, Injectable, signal } from "@angular/core";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import type { ExtendedUserModelType, UserAuthDetailsModelType, UserModelType } from "../models";
import { UsersApiService } from "./users-api.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly _usersApiService = inject(UsersApiService);

  currentUser = signal<UserModelType | UserAuthDetailsModelType | ExtendedUserModelType | undefined>(undefined, {
    equal: (a, b) => a?.email === b?.email,
  });

  public fetchUser() {
    this._usersApiService.getUserInfo().subscribe({
      next: (res) => {
        if (!res.inError) {
          this.currentUser.update(() => res.responseData);
        }

        const storedUser = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
          type: "json",
        }) as UserAuthDetailsModelType;

        this.currentUser.update(() => storedUser);
      },
    });
  }
}
