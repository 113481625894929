/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, Injectable, isDevMode, signal } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as htmlToImage from "html-to-image";
import { Observable } from "rxjs";

import { AdaaHelper } from "../../core/utils";
import { Constants } from "../constants/constants";
import {
  AdaaBoolean,
  CellType,
  FilterType,
  ImportExportStatusValues,
  ImportExportType,
  Language,
} from "../constants/enums";
import { SortEvent } from "../directives";
import {
  EntityModelType,
  GovDirection,
  OrderConfig,
  OrgUnit,
  ParameterCatalog,
  RangeFilter,
  SearchFields,
  ServiceModel,
  SubGovDirection,
  TableState,
  ValueText,
} from "../models";
import {
  agmCyclesDropdownList,
  auditTrailDropdownList,
  auxVarServicesDropdownList,
  cyclesDropdownList,
  entitiesDropdownList,
  govDirectionDropdownList,
  itemTypesDropdownList,
  mainServicesDropdownList,
  notificationCategoryDropdownList,
  objectivesDropdownList,
  orgUnitDropdownList,
  otherEntitiesDropdownList,
  pillarsDropdownList,
  pillarTypeDropdownList,
  subGovDirectionDropdownList,
  tpAgreementEntitiesDropdownList,
  tpAgreementUsersDropdownList,
  tpCyclesDropdownList,
  tpDropdownList,
  userGroupsDropdownList,
  userGroupsHelpDesk,
} from "./data-table-utils.service";
import { MainService } from "./main.service";
import { OrgUnitApiService } from "./org-unit-api.service";
import { PropertiesService } from "./properties.service";
import { UsersApiService } from "./users-api.service";

@Injectable({
  providedIn: "root",
})
export class DataTableService {
  private _mainService = inject(MainService);
  private _translateService = inject(TranslateService);
  private _router = inject(Router);
  private _propertiesService = inject(PropertiesService);
  private _usersService = inject(UsersApiService);
  private _orgUnitApiService = inject(OrgUnitApiService);

  currentSelectedEntityId = signal<number | undefined>(AdaaHelper.entity?.id);

  private _savedTablePages: {
    [key: string]: TableState;
  } = {};

  private _asyncDropdownListOptions = {
    entities: entitiesDropdownList(),
    orgUnits: orgUnitDropdownList(),
    subGovDirections: subGovDirectionDropdownList(),
    govDirections: govDirectionDropdownList(),
    cycles: cyclesDropdownList(),
    itemTypes: itemTypesDropdownList(),
    auditTrail: auditTrailDropdownList(),
    otherEntities: otherEntitiesDropdownList(),
    tp: tpDropdownList(),
    objectives: objectivesDropdownList(),
    userGroups: userGroupsDropdownList(),
    notificationCategories: notificationCategoryDropdownList(),
    tpCycles: tpCyclesDropdownList(),
    mainServices: mainServicesDropdownList(),
    auxVarService: auxVarServicesDropdownList(),
    tpAgreementUsers: tpAgreementUsersDropdownList(),
    tpAgreementEntities: tpAgreementEntitiesDropdownList(),
    pillarTypes: pillarTypeDropdownList(),
    agmProjectCycles: agmCyclesDropdownList(),
    helpDesk: userGroupsHelpDesk(),
    pillar: pillarsDropdownList(),
  };

  /**
   * Used to keep a copy of the ddl options
   * Only use this for the filters that have a relations
   */
  private _optionsDdlCopy: {
    orgUnit: OrgUnit[];
    mainService: ServiceModel[];
    auxVarService: ServiceModel[];
    govDirs: GovDirection[];
    subGovDir: SubGovDirection[];
    entities: EntityModelType[];
  } = {
    orgUnit: [],
    mainService: [],
    auxVarService: [],
    govDirs: [],
    subGovDir: [],
    entities: [],
  };

  readonly filtersHaveEffect: string[] = [
    "entityNameEN",
    "entityNameAE",
    "sponsorEntityNameEN",
    "sponsorEntityNameAE",
    "orgUnitNameEN",
    "orgUnitNameAE",
    "govDirectionNameEN",
    "govDirectionNameAE",
    "mainServiceNameEN",
    "mainServiceNameAE",
  ];

  public getConfig(key: string) {
    const userId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, { type: "prop", property: "id" });
    const url = `userformconfig/getCatalogByUserIdAndSearchKey/${userId}/${key}`;

    return this._mainService.sendRequest({
      method: "GET",
      url,
    });
  }

  /**
   * @description Here we have a several conditions to check before showing any settings in the table, we might have more in the future
   * with complicated scenarios so this is why we keep it here in separate service
   * @note Make sure to take care of "actions" column since it's a static column, you might forget to handle it somewhere.
   * @param type
   * @param configListParameters
   * @param enableActionsCell
   * @param language
   */
  public getTableSettings(
    type: "displayColumns" | "originalColumns",
    configListParameters: ParameterCatalog[],
    enableActionsCell: boolean,
    language: Language
  ): string[] {
    const displayedColumns: string[] = [];
    const originalColumns: string[] = [];
    configListParameters.sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));

    if (configListParameters) {
      configListParameters.forEach((item) => {
        if (!displayedColumns.includes(item.fieldName)) {
          originalColumns.unshift(item.fieldName);
          if (
            item.userVisibleParameter !== AdaaBoolean.N &&
            item.visibleParameter !== AdaaBoolean.N &&
            (item.step === language || !item.step) &&
            (item.lang === language || !item.lang)
          ) {
            // Mobile should show different columns based on mobileDisplay property
            // eslint-disable-next-line no-constant-condition
            if (false) {
              //TODO:FIX ME
            } else {
              displayedColumns.unshift(item.fieldName);
            }
          }
        }
      });
      if (enableActionsCell) {
        displayedColumns.push("actions");
      }
    }

    if (type === "displayColumns") {
      return displayedColumns;
    } else {
      return originalColumns;
    }
  }

  /**
   * @description Get a sorting details using searchFields then return the results as a dataTableOutput
   * @param configListParameters
   * @param sort
   */
  public getSortAndFilterData(configListParameters: ParameterCatalog[], value: any, sort?: SortEvent): SearchFields {
    const searchField: SearchFields = { searchFields: {} };

    configListParameters.forEach((column) => {
      const sortValue = sort?.column === column.fieldName ? (sort.direction === "asc" ? "asc" : "desc") : null;
      const filterValue =
        column.filterType !== FilterType.dropdownMulti &&
        column.filterType !== FilterType.dateRange &&
        column.filterType !== FilterType.valueRange &&
        column.filterType !== FilterType.YearRange
          ? value
            ? value[column.fieldName]
            : null
          : null;
      const lovValue = column.filterType === FilterType.dropdownMulti ? (value ? value[column.fieldName] : null) : null;
      const rangeValue =
        column.filterType === FilterType.dateRange ||
        column.filterType === FilterType.valueRange ||
        column.filterType === FilterType.YearRange
          ? value
            ? this._getRangeValue(
                value[column.fieldName],
                column.filterType === FilterType.dateRange || column.filterType === FilterType.YearRange
              )
            : null
          : null;

      if (
        AdaaHelper.isDefined(filterValue) ||
        AdaaHelper.isDefined(sortValue) ||
        AdaaHelper.isDefined(lovValue) ||
        AdaaHelper.isDefined(rangeValue)
      ) {
        Object.defineProperty(searchField.searchFields, column.dbFieldName, {
          value: {
            order: AdaaHelper.isDefined(sortValue) ? sortValue : null,
            value: AdaaHelper.isDefined(filterValue) ? filterValue : null,
            lov: AdaaHelper.isDefined(lovValue) ? lovValue : null,
            range: AdaaHelper.isDefined(rangeValue) ? rangeValue : null,
          },
          writable: true,
          enumerable: true,
          configurable: true,
        });
      }
    });
    return searchField;
  }

  /**
   * @description Get the sort config after the user reoreder the table columns
   * @param tableColumns
   */
  public saveColumnsConfig(tableColumns: ParameterCatalog[]) {
    const url = `userformconfig/create?isFinish=false`;
    const config = this._getColumnsConfig(tableColumns);

    return this._mainService.sendRequest({
      method: "POST",
      url,
      data: config,
    });
  }

  public checkColumnsForFilterModal(tableColumns: ParameterCatalog[], configKey: string): ParameterCatalog[] {
    tableColumns.forEach((column) => {
      if (
        column.filterType === FilterType.dropdownMulti ||
        column.filterType === FilterType.dropdownTree ||
        column.filterType === FilterType.dropdownSingle
      ) {
        this._getDropdownListForFilter(column.fieldName, column.fieldType, column.searchKey, configKey).subscribe(
          (resp) => {
            column.dropdown = resp;
          }
        );
      }
    });

    return tableColumns;
  }

  public printTable(tableId: string, pageTitle: string) {
    const table = document.getElementById(tableId);
    const entityIcon = document.querySelector(".entity-logo") as HTMLElement;
    const adaaIcon = document.querySelector(".adaa-logo") as HTMLElement;

    Promise.all([htmlToImage.toSvg(table!), htmlToImage.toSvg(entityIcon), htmlToImage.toSvg(adaaIcon)])
      .then((values) => {
        const popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
        if (!popupWin) return;

        popupWin.document.body.innerHTML = this._buildPrintedTableHtml(
          this._translateService.instant(pageTitle),
          values[0],
          values[1],
          values[2]
        );

        setTimeout(() => {
          popupWin.focus();
          popupWin.print();
        }, 1000);
      })
      .catch(function (error) {
        if (isDevMode() && window.location.hostname === "localhost") {
          // eslint-disable-next-line no-console
          console.log(
            "%c Hey Developer: Please check why can't print the table" + error,
            "background: #222; color: red; font-size: 1.5em"
          );
        }
      });
  }

  public filterDataLocally(
    searchFields: SearchFields,
    tableColumns: ParameterCatalog[],
    localData: object[]
  ): object[] {
    if (!AdaaHelper.isDefined(searchFields) || !AdaaHelper.isDefined(searchFields.searchFields)) return localData;

    let results = AdaaHelper.clone(localData);
    const fields = searchFields.searchFields;
    Object.keys(fields).forEach((item) => {
      if (typeof fields[item]?.value === "string") {
        results = this._filterByValue(results, item, fields, tableColumns);
      } else if (fields[item]?.lov) {
        results = this._filterByLov(results, item, fields);
      } else if (fields[item]?.range) {
        results = this._filterByRange(results, item, fields, tableColumns);
      }
    });

    return results;
  }

  public sortDataLocally(event: SortEvent, localData: object[]): object[] {
    const results = AdaaHelper.clone(localData);

    localData = results.sort((a: any, b: any) => {
      return event.direction !== "desc"
        ? this._orderByComparator(a[event.column], b[event.column])
        : -this._orderByComparator(a[event.column], b[event.column]);
    });

    return localData;
  }

  /**
   * To save the table state
   * @param key
   * @param state
   */
  public setPageTableState(configKey: string, state: TableState): void {
    const route = this._getCurrentRouteName();
    this._savedTablePages[`${configKey}_${route}`] = state;
  }

  /**
   * To get the table state
   * @param configKey
   * @returns
   */
  public getPageTableState(configKey: string): TableState | undefined {
    const route = this._getCurrentRouteName();
    return this._savedTablePages[`${configKey}_${route}`];
  }

  /**
   * To reset all the table state
   */
  public resetAllTablesState(): void {
    this._savedTablePages = {};
  }

  /**
   * Use this method to get the updated table columns with the dropdown options
   * Only use it if you have relation between two dropdowns in the data table
   * @param value
   * @param item
   * @param columns
   * @returns
   */
  public updateTableColumns(
    value: any,
    { item, configKey }: { item: ParameterCatalog; configKey: string },
    columns: ParameterCatalog[]
  ): Observable<ParameterCatalog[]> {
    if (
      (configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.IMPROVEMENT_PLAN ||
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.PMO_HOMEPAGE_PERF_AGG_CONF_LIST) &&
      item.fieldName === AdaaHelper.getFieldLanguage("orgUnitName")
    ) {
      return new Observable<ParameterCatalog[]>((subscriber) => {
        //ownerOrgUnitName for NTKPI table - ownerName for the reset of tables
        const ownerColumn = columns.find(
          (e) =>
            e.fieldName === AdaaHelper.getFieldLanguage("ownerName") ||
            e.fieldName === AdaaHelper.getFieldLanguage("ownerOrgUnitName")
        );

        const orgUnit = this._optionsDdlCopy.orgUnit.find((e) =>
          AdaaHelper.getCurrentLang() === Language.Arabic ? e.nameAE === value : e.nameEN === value
        );

        if (!orgUnit) {
          if (ownerColumn) ownerColumn.dropdown = [];

          subscriber.next(columns);
          return;
        }

        this._usersService.getOwnersPerOrgUnit(orgUnit.id).subscribe({
          next: (response) => {
            if (response.inError) return;

            if (!ownerColumn) return;

            ownerColumn.dropdown = AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name")
            );

            subscriber.next(columns);
          },
        });
      });
    }

    if (this._isEntityAndOrgUnitLinked(configKey, item.fieldName)) {
      return new Observable<ParameterCatalog[]>((subscriber) => {
        const orgUnitColumn = columns.find((e) => e.fieldName === AdaaHelper.getFieldLanguage("orgUnitName"));
        //ownerOrgUnitName for NTKPI table - ownerName for the reset of tables
        const ownerColumn = columns.find(
          (e) =>
            e.fieldName === AdaaHelper.getFieldLanguage("ownerName") ||
            e.fieldName === AdaaHelper.getFieldLanguage("ownerOrgUnitName")
        );

        const entity = this._optionsDdlCopy.entities.find((e) =>
          AdaaHelper.getCurrentLang() === Language.Arabic ? e.nameAE === value[0] : e.nameEN === value[0]
        );

        if (!entity) {
          //No Entity reset orgunit and owner to empty
          if (orgUnitColumn) orgUnitColumn.dropdown = [];
          if (ownerColumn) ownerColumn.dropdown = [];

          subscriber.next(columns);
          return;
        }

        this._orgUnitApiService.getAllByEntity(entity.id).subscribe({
          next: (response) => {
            if (response.inError) return;

            if (!orgUnitColumn) return;

            //Update the orgUnit Copy
            this._optionsDdlCopy.orgUnit = response.responseData;

            //Entity change reset orgunit and reset the owner to empty
            orgUnitColumn.dropdown = AdaaHelper.setDropdownArray(
              response.responseData,
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("name"),
              AdaaHelper.getFieldLanguage("parentOrgUnitName")
            );
            if (ownerColumn) ownerColumn.dropdown = [];

            subscriber.next(columns);
          },
        });
      });
    }

    return new Observable<ParameterCatalog[]>((subscriber) => {
      switch (item.fieldName) {
        case AdaaHelper.getFieldLanguage("orgUnitName"): {
          const orgUnit = this._optionsDdlCopy.orgUnit.find((e) =>
            AdaaHelper.getCurrentLang() === Language.Arabic ? e.nameAE === value : e.nameEN === value
          );

          if (!orgUnit) break;

          this._usersService.getOwnersPerOrgUnit(orgUnit.id).subscribe({
            next: (response) => {
              if (response.inError) return;

              const ownerColumn = columns.find((e) => e.fieldName === AdaaHelper.getFieldLanguage("ownerOrgUnitName"));
              if (!ownerColumn) return;

              ownerColumn.dropdown = AdaaHelper.setDropdownArray(
                response.responseData,
                AdaaHelper.getFieldLanguage("name"),
                AdaaHelper.getFieldLanguage("name")
              );

              subscriber.next(columns);
            },
          });
          break;
        }

        case AdaaHelper.getFieldLanguage("govDirectionName"): {
          const govDirNames = value ?? [];
          const govDirIds = [];
          const values: ValueText[] = [];

          for (const name of govDirNames) {
            const data = this._optionsDdlCopy.govDirs.find((e) =>
              AdaaHelper.getCurrentLang() === Language.Arabic ? e.nameAE === name : e.nameEN === name
            );
            if (!data) continue;
            else govDirIds.push(data.id);
          }

          if (!govDirIds.length) {
            columns.forEach((e) => {
              if (e.fieldName === AdaaHelper.getFieldLanguage("subGovDirectionName")) {
                e.dropdown = AdaaHelper.setDropdownArray(
                  this._optionsDdlCopy.subGovDir,
                  AdaaHelper.getFieldLanguage("name"),
                  AdaaHelper.getFieldLanguage("name")
                );
              }
            });

            break;
          }

          for (const id of govDirIds) {
            const subGov = this._optionsDdlCopy.subGovDir.filter(({ govDirectionId }) => govDirectionId === id);
            if (!subGov.length) continue;
            else {
              values.push(
                ...AdaaHelper.setDropdownArray(
                  subGov,
                  AdaaHelper.getFieldLanguage("name"),
                  AdaaHelper.getFieldLanguage("name")
                )
              );
            }
          }

          columns.forEach((e) => {
            if (e.fieldName === AdaaHelper.getFieldLanguage("subGovDirectionName")) {
              e.dropdown = [...values];
            }
          });

          subscriber.next(columns);

          break;
        }

        case AdaaHelper.getFieldLanguage("mainServiceName"): {
          const mainNames = value ?? [];
          const mainIds = [];
          const values: ValueText[] = [];

          for (const name of mainNames) {
            const data = this._optionsDdlCopy.mainService.find((e) =>
              AdaaHelper.getCurrentLang() === Language.Arabic ? e.nameAE === name : e.nameEN === name
            );
            if (!data) continue;
            else mainIds.push(data.id);
          }

          if (!mainIds.length) {
            columns.forEach((e) => {
              if (e.fieldName === AdaaHelper.getFieldLanguage("auxVarServiceName")) {
                e.dropdown = AdaaHelper.setDropdownArray(
                  this._optionsDdlCopy.auxVarService,
                  AdaaHelper.getFieldLanguage("name"),
                  AdaaHelper.getFieldLanguage("name")
                );
              }
            });

            break;
          }

          for (const id of mainIds) {
            const auxVarService = this._optionsDdlCopy.auxVarService.filter(({ parentId }) => parentId === id);
            if (!auxVarService.length) continue;
            else {
              values.push(
                ...AdaaHelper.setDropdownArray(
                  auxVarService,
                  AdaaHelper.getFieldLanguage("name"),
                  AdaaHelper.getFieldLanguage("name")
                )
              );
            }
          }

          columns.forEach((e) => {
            if (e.fieldName === AdaaHelper.getFieldLanguage("auxVarServiceName")) {
              e.dropdown = [...values];
            }
          });

          subscriber.next(columns);

          break;
        }

        default:
          subscriber.next(columns);
          break;
      }
    });
  }

  /**
   * Handle the archived plan permission for KPIs, Objectives, Projects, Activities, ...etc.
   * @param itemId
   * @returns
   */
  public checkIfNotArchived(itemId: number): boolean {
    const isNotArchived: boolean = true;
    const planStatus =
      AdaaHelper.getLocalStorage(Constants.localStorageKeys.currentPlan, { type: "prop", property: "status" }) ?? 0;

    if (this._checkIfItemEffectedByCycle(itemId)) {
      return planStatus !== Constants.OBJECT_STATUS.ARCHIVED && planStatus !== Constants.OBJECT_STATUS.CLOSED;
    }
    return isNotArchived;
  }

  /**
   * Get the default sort for the table if the user defined mandatory as Y from DB
   * @param configListParameters
   * @param language
   */
  public getDefaultSort(configListParameters: ParameterCatalog[], language: Language): SortEvent {
    configListParameters.sort((a, b) => (a.displayOrder < b.displayOrder ? -1 : 1));

    const sortCol = configListParameters.find(
      (e) =>
        e.userVisibleParameter !== AdaaBoolean.N &&
        e.visibleParameter !== AdaaBoolean.N &&
        (e.step === language || !e.step) &&
        (e.lang === language || !e.lang) &&
        e.mandatory === AdaaBoolean.Y
    );

    return {
      column: sortCol ? sortCol.fieldName : "",
      direction: sortCol ? "asc" : "",
    };
  }

  private _getDropdownListForFilter(
    fieldName: string,
    cellType: string,
    searchKey: string,
    configKey: string
  ): Observable<ValueText[]> {
    return new Observable<ValueText[]>((subscriber) => {
      const propType = this._getPropType(fieldName, searchKey, configKey);
      if (propType) {
        this._propertiesService.getPropById(propType).subscribe((response) => {
          if (response.inError) return;

          const properties = response.responseData.filter((e) => e.enabled === AdaaBoolean.Y);

          subscriber.next(
            AdaaHelper.setDropdownArray(
              properties,
              this._getDdlFilterValue(fieldName, configKey),
              AdaaHelper.getFieldLanguage("name")
            )
          );
        });
      } else if (
        AdaaHelper.isDefinedAndNotEmpty(cellType) &&
        (cellType === CellType.yesNo ||
          cellType === CellType.trueFalse ||
          cellType === CellType.status ||
          cellType === CellType.enabled ||
          cellType === CellType.checkbox)
      ) {
        this._getDropdownListByCellType(cellType).subscribe((reps) => subscriber.next(reps));
      } else if (AdaaHelper.isDefinedAndNotEmpty(fieldName)) {
        this._getDropdownListByFieldName(fieldName, configKey).subscribe((resp) => subscriber.next(resp));
      }
    });
  }

  private _getDropdownListByCellType(cellType: string): Observable<ValueText[]> {
    return new Observable<ValueText[]>((subscriber) => {
      if (cellType === CellType.yesNo || cellType === CellType.trueFalse || cellType === CellType.checkbox) {
        this._translateService.get(["common.form.label.yes", "common.form.label.no"]).subscribe((values) => {
          subscriber.next([
            {
              value: cellType === CellType.checkbox || cellType === CellType.yesNo ? AdaaBoolean.Y : 1,
              text: values["common.form.label.yes"],
            },
            {
              value: cellType === CellType.checkbox || cellType === CellType.yesNo ? AdaaBoolean.N : 0,
              text: values["common.form.label.no"],
            },
          ]);
        });
      } else if (cellType === CellType.status) {
        this._translateService.get(["status.1", "status.3", "status.in_review"]).subscribe((values) => {
          subscriber.next([
            {
              value: Constants.OBJECT_STATUS.ACTIVE,
              text: values["status.1"],
            },
            {
              value: Constants.OBJECT_STATUS.DRAFT,
              text: values["status.3"],
            },
            {
              value: Constants.OBJECT_STATUS.IN_REVIEW,
              text: values["status.in_review"],
            },
          ]);
        });
      } else if (cellType === CellType.enabled) {
        this._translateService.get(["common.form.label.active", "common.form.label.inactive"]).subscribe((values) => {
          subscriber.next([
            {
              value: AdaaBoolean.Y,
              text: values["common.form.label.active"],
            },
            {
              value: AdaaBoolean.N,
              text: values["common.form.label.inactive"],
            },
          ]);
        });
      }
    });
  }

  private _getDropdownListByFieldName(fieldName: string, configKey: string): Observable<ValueText[]> {
    return new Observable<ValueText[]>((subscriber) => {
      //@note: Not an async operation
      if (fieldName === "operationType" || fieldName.includes("operationType")) {
        subscriber.next([
          {
            value:
              fieldName === "operationType"
                ? Constants.CONSTANT_WORKFLOW_WFTYPES.APPROVAL
                : this._translateService.instant("workflow.approval"),
            text: this._translateService.instant("workflow.approval"),
          },
          {
            value:
              fieldName === "operationType"
                ? Constants.CONSTANT_WORKFLOW_WFTYPES.REVIEW
                : this._translateService.instant("workflow.review"),
            text: this._translateService.instant("workflow.review"),
          },
        ]);
      }

      if (fieldName.includes("nationalProjectStatus") || fieldName.includes("annualMeetingProjectStatus")) {
        subscriber.next([
          {
            value: this._translateService.instant("national_projects.completed"),
            text: this._translateService.instant("national_projects.completed"),
          },
          {
            value: this._translateService.instant("national_projects.ongoing"),
            text: this._translateService.instant("national_projects.ongoing"),
          },
          {
            value: this._translateService.instant("national_projects.delayed"),
            text: this._translateService.instant("national_projects.delayed"),
          },
        ]);
      }

      if (fieldName.includes("type") && configKey.includes(Constants.MAIN_TABLE_LIST_CONF_KEY.METRICS)) {
        this._translateService.get(["main_types.G", "main_types.L", "main_types.D"]).subscribe((values) => {
          subscriber.next([
            { value: Constants.METRICS_TYPE.GLOBAL, text: values["main_types.G"] },
            { value: Constants.METRICS_TYPE.LOCAL, text: values["main_types.L"] },
            { value: Constants.METRICS_TYPE.DIMENSION, text: values["main_types.D"] },
          ]);
        });
      }

      if (fieldName.includes("type") && configKey.includes(Constants.MAIN_TABLE_LIST_CONF_KEY.IMPORT_EXPORT)) {
        this._translateService
          .get(["importExport.operation_type.import", "importExport.operation_type.export"])
          .subscribe((values) => {
            subscriber.next([
              { value: ImportExportType.EXPORT, text: values["importExport.operation_type.export"] },
              { value: ImportExportType.IMPORT, text: values["importExport.operation_type.import"] },
            ]);
          });
      }

      if (fieldName.includes("wfStatus") && configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_HISTORY) {
        this._translateService
          .get(["wf_action_decision.approved", "wf_action_decision.rejected"])
          .subscribe((values) => {
            subscriber.next([
              { value: values["wf_action_decision.approved"], text: values["wf_action_decision.approved"] },
              { value: values["wf_action_decision.rejected"], text: values["wf_action_decision.rejected"] },
            ]);
          });
      }

      if (fieldName.includes("impextStatus")) {
        this._translateService
          .get([
            "importExport.file_status.success",
            "importExport.file_status.done",
            "importExport.file_status.pending",
            "importExport.file_status.fail",
          ])
          .subscribe((values) => {
            subscriber.next([
              { value: ImportExportStatusValues.SUCCESS, text: values["importExport.file_status.success"] },
              { value: ImportExportStatusValues.DONE, text: values["importExport.file_status.done"] },
              { value: ImportExportStatusValues.PENDING, text: values["importExport.file_status.pending"] },
              { value: ImportExportStatusValues.FAIL, text: values["importExport.file_status.fail"] },
            ]);
          });
      }

      //dimensions sync table
      if (fieldName.includes("logType")) {
        this._translateService
          .get(["dimension.log_type_values.value", "dimension.log_type_values.structure"])
          .subscribe((values) => {
            subscriber.next([
              { value: values["dimension.log_type_values.value"], text: values["dimension.log_type_values.value"] },
              {
                value: values["dimension.log_type_values.structure"],
                text: values["dimension.log_type_values.structure"],
              },
            ]);
          });
      }

      //dimensions sync table
      if (fieldName.includes("result")) {
        this._translateService
          .get(["dimension.result_values.success", "dimension.result_values.started", "dimension.result_values.failed"])
          .subscribe((values) => {
            subscriber.next([
              { value: values["dimension.result_values.success"], text: values["dimension.result_values.success"] },
              {
                value: values["dimension.result_values.started"],
                text: values["dimension.result_values.started"],
              },
              {
                value: values["dimension.result_values.failed"],
                text: values["dimension.result_values.failed"],
              },
            ]);
          });
      }

      if (
        fieldName.includes("action") &&
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.DIMENSIONS_SYNC_LOGS_DETAILS
      ) {
        this._translateService
          .get(["dimension.action_values.create", "dimension.action_values.update"])
          .subscribe((values) => {
            subscriber.next([
              { value: values["dimension.action_values.create"], text: values["dimension.action_values.create"] },
              {
                value: values["dimension.action_values.update"],
                text: values["dimension.action_values.update"],
              },
            ]);
          });
      }

      this._asyncDropdownListOptions.tp(subscriber, { fieldName });
      this._asyncDropdownListOptions.itemTypes(subscriber, { fieldName });
      this._asyncDropdownListOptions.cycles(subscriber, { fieldName });
      this._asyncDropdownListOptions.entities(subscriber, {
        fieldName,
        configKey,
        cb: (data) => (this._optionsDdlCopy.entities = data ?? []),
      });
      this._asyncDropdownListOptions.objectives(subscriber, { fieldName });
      this._asyncDropdownListOptions.otherEntities(subscriber, { fieldName });
      this._asyncDropdownListOptions.auditTrail(subscriber, { fieldName, configKey });
      this._asyncDropdownListOptions.userGroups(subscriber, { fieldName });
      this._asyncDropdownListOptions.notificationCategories(subscriber, { fieldName, configKey });
      this._asyncDropdownListOptions.tpCycles(subscriber, { fieldName });
      this._asyncDropdownListOptions.mainServices(subscriber, {
        fieldName,
        cb: (data) => (this._optionsDdlCopy.mainService = data ?? []),
      });
      this._asyncDropdownListOptions.auxVarService(subscriber, {
        fieldName,
        cb: (data) => (this._optionsDdlCopy.auxVarService = data ?? []),
      });
      this._asyncDropdownListOptions.tpAgreementUsers(subscriber, { fieldName });
      this._asyncDropdownListOptions.tpAgreementEntities(subscriber, { fieldName, configKey });
      this._asyncDropdownListOptions.govDirections(subscriber, {
        fieldName,
        cb: (data) => (this._optionsDdlCopy.govDirs = data ?? []),
      });
      this._asyncDropdownListOptions.subGovDirections(subscriber, {
        fieldName,
        cb: (data) => (this._optionsDdlCopy.subGovDir = data ?? []),
      });
      this._asyncDropdownListOptions.orgUnits(subscriber, {
        fieldName,
        entityId: this.currentSelectedEntityId(),
        cb: (data) => (this._optionsDdlCopy.orgUnit = data ?? []),
      });
      this._asyncDropdownListOptions.pillarTypes(subscriber, { fieldName });
      this._asyncDropdownListOptions.agmProjectCycles(subscriber, { fieldName });
      this._asyncDropdownListOptions.helpDesk(subscriber, { fieldName, entityId: this.currentSelectedEntityId() });
      this._asyncDropdownListOptions.pillar(subscriber, { fieldName });
    });
  }

  /**
   * @description The general API to find propType and call it to fetch the dropdown
   * @param field
   * @param searchKey // to know the diffrence between keys in case we have same field with 2 (dropdown list).
   */
  private _getPropType(field: string, searchKey: string, configKey: string): number | null {
    switch (field) {
      case "trend":
        return Constants.CONSTANT_TREND;

      case AdaaHelper.getFieldLanguage("valueTypeName"):
        return Constants.CONSTANT_FORMULA_LLK;

      case AdaaHelper.getFieldLanguage("measurementUnitName"):
        return Constants.CONSTANT_MEASUREMENT_UNIT;

      case AdaaHelper.getFieldLanguage("frequencyName"):
      case AdaaHelper.getFieldLanguage("frequency"):
        return Constants.CONSTANT_FREQUENCY;

      case AdaaHelper.getFieldLanguage("applicableTo"):
        return Constants.CONSTANT_KPITYPE_PROP;

      case AdaaHelper.getFieldLanguage("typeName"):
        if (configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.USERS_GROUPS) return Constants.CONSTANT_SUBGOVDIRECTION;
        else if (
          configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.SERVICES ||
          configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.KHADAMATI_DETAIL
        )
          return Constants.CONSTANT_SERVICETYPES_TYPE;
        else return Constants.CONSTANT_BENCHTYPE;

      case AdaaHelper.getFieldLanguage("roleName"):
        return Constants.CONSTANT_EXEC_TEAM_MEMBER_TITLE_TYPE;

      case AdaaHelper.getFieldLanguage("actionDetail"):
      case AdaaHelper.getFieldLanguage("actionType"):
      case AdaaHelper.getFieldLanguage("actionTypeName"):
      case AdaaHelper.getFieldLanguage("categoryName"):
        if (
          configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_MONITORING ||
          configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.WORKFLOW_CONFIGURATION ||
          configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.AUDIT_TRAIL
        )
          return Constants.CONSTANT_ACTIONTYPES;
        else return null;

      case AdaaHelper.getFieldLanguage("dataType"):
        if (searchKey.indexOf("metrics") > -1) return Constants.CONSTANT_MEASUREMENT_UNIT_METRICS;
        if (searchKey.indexOf("attributes") > -1) return Constants.CONSTANT_MEASUREMENT_UNIT_ATTRIBUTES;
        else return null;

      case AdaaHelper.getFieldLanguage("operationTypeName"):
        return Constants.CONSTANT_WFTYPES;

      case AdaaHelper.getFieldLanguage("itemTypeName"):
        return Constants.CONSTANT_ITEMTYPES;

      case AdaaHelper.getFieldLanguage("ruleSetTypeName"):
        return Constants.CONSTANT_RULESET_TYPE;

      case AdaaHelper.getFieldLanguage("scopeName"):
        return Constants.CONSTANT_RULESET_SCOPE;

      case AdaaHelper.getFieldLanguage("lastResultName"):
        return Constants.CONSTANT_RULESET_EXECUTION_RESULT;

      case AdaaHelper.getFieldLanguage("severityName"):
        return Constants.CONSTANT_RULESET_SEVERITY;

      case AdaaHelper.getFieldLanguage("priority"):
        return Constants.CONSTANT_PERIORITY;

      case AdaaHelper.getFieldLanguage("currentStatus"):
        return Constants.CONSTANT_STATUS;

      case AdaaHelper.getFieldLanguage("object"):
        return Constants.CONSTANT_ITEMS;

      case "publishedState":
        return Constants.CONSTANT_PUBLISHED_STATUS;

      case AdaaHelper.getFieldLanguage("targetCategoryName"):
        return Constants.CONSTANT_TARGET_CATEGORY;

      case AdaaHelper.getFieldLanguage("objectiveTypeName"):
        return Constants.CONSTANT_OBJECTIVE_TYPE_PROP_ID;

      case "status":
        if (configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.KHADAMATI)
          return Constants.CONSTANT_KHADAMATI_IMPORT_STATUS;
        else if (configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.CYCLES) return Constants.CONSTANT_CYCLE_STATUS;
        else return null;

      case AdaaHelper.getFieldLanguage("entitySizeName"):
        return Constants.CONSTANT_ENTITY_SIZE;

      case AdaaHelper.getFieldLanguage("entityTypeName"):
        return Constants.CONSTANT_ENTITY_TYPE;

      case AdaaHelper.getFieldLanguage("attachTypeName"):
        return Constants.CONSTANT_ATTACHTYPE;

      case AdaaHelper.getFieldLanguage("mainOutcomeTypeName"):
        return Constants.CONSTANT_MAIN_OUTCOME_TYPE_PROP;

      case AdaaHelper.getFieldLanguage("action"):
        return configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.KHADAMATI_DETAIL
          ? Constants.CONSTANT_KHADAMATI_DETAILS_ACTIONS
          : null;

      default:
        return null;
    }
  }

  /**
   * @description Get the key value to the dropdown
   * @param field
   */
  private _getDdlFilterValue(field: string, configKey: string): string {
    switch (field) {
      case AdaaHelper.getFieldLanguage("frequencyName"):
      case AdaaHelper.getFieldLanguage("valueTypeName"):
      case AdaaHelper.getFieldLanguage("categoryName"):
      case AdaaHelper.getFieldLanguage("itemTypeName"):
      case AdaaHelper.getFieldLanguage("objectiveTypeName"):
      case AdaaHelper.getFieldLanguage("actionDetail"):
      case AdaaHelper.getFieldLanguage("objectType"):
      case AdaaHelper.getFieldLanguage("itemType"):
      case AdaaHelper.getFieldLanguage("typeName"):
      case AdaaHelper.getFieldLanguage("actionTypeName"):
      case AdaaHelper.getFieldLanguage("dataType"):
      case AdaaHelper.getFieldLanguage("entitySizeName"):
      case AdaaHelper.getFieldLanguage("entityTypeName"):
      case AdaaHelper.getFieldLanguage("currentStatus"):
      case AdaaHelper.getFieldLanguage("object"):
      case AdaaHelper.getFieldLanguage("priority"):
      case AdaaHelper.getFieldLanguage("frequency"):
      case AdaaHelper.getFieldLanguage("attachTypeName"):
      case AdaaHelper.getFieldLanguage("mainOutcomeTypeName"):
      case AdaaHelper.getFieldLanguage("annualMeetingProjectCycleName"):
      case AdaaHelper.getFieldLanguage("operationTypeName"):
      case AdaaHelper.getFieldLanguage("enablerEntityName"):
      case AdaaHelper.getFieldLanguage("action"):
      case AdaaHelper.getFieldLanguage("measurementUnitName"):
      case AdaaHelper.getFieldLanguage("targetCategoryName"):
        return AdaaHelper.getFieldLanguage("name");
      case "status":
        return configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.CYCLES ? "value" : "id";
      default:
        return "id";
    }
  }

  /**
   * @description To get the start/end values if the filter is date range
   * @param value
   * @param isDateRange
   */
  private _getRangeValue(value: string, isDateRange: boolean): RangeFilter | null {
    const range: RangeFilter = {
      isDBDateTime: isDateRange ? AdaaBoolean.Y : AdaaBoolean.N,
      startNumeric: null,
      endNumeric: null,
    };

    if (!AdaaHelper.isDefinedAndNotEmpty(value)) return null;

    const valuesArr = value.split("-");
    if (!AdaaHelper.isDefined(valuesArr)) return null;

    if (AdaaHelper.isDefined(valuesArr[0]) && AdaaHelper.isDefined(valuesArr[0].replaceAll(" ", ""))) {
      range.startNumeric = parseFloat(valuesArr[0]);
    }

    if (AdaaHelper.isDefined(valuesArr[1]) && AdaaHelper.isDefined(valuesArr[1].replaceAll(" ", ""))) {
      range.endNumeric = parseFloat(valuesArr[1]);
    }

    return range;
  }

  /**
   * @description Get the sort config after the user reoreder the table columns
   * @param tableColumns
   */
  private _getColumnsConfig(tableColumns: ParameterCatalog[]): OrderConfig {
    tableColumns.forEach((e, i) => {
      e.displayOrder = i + 1;
    });

    const userId = AdaaHelper.getLocalStorage(Constants.localStorageKeys.user, {
      type: "prop",
      property: "id",
    }) as string;

    return {
      formId: tableColumns[0].formId,
      status: tableColumns[0].status,
      userId: Number(userId),
      configuration: JSON.stringify(tableColumns),
    };
  }

  /**
   * @description Get html for the printed table
   * @param object
   */
  private _buildPrintedTableHtml(
    pageTitle: string,
    tableDataUrl: string,
    entityLogo: string,
    adaaLogo: string
  ): string {
    return `<html>
        <head><title>${pageTitle}</title></head>
        <body style="width: 100%; padding: 0; margin: 0;" onload="window.print(); window.close();">
        <table width="100%"><tbody>
        <tr>
        <td width="50%"><img src="${entityLogo}" /></td>
        <td width="50%" style="text-align:right"><img src="${adaaLogo}" /></td>
        </tr>
        </tbody></table>
        <h1>${pageTitle}</h1>
        <img src="${tableDataUrl}" width"100%"/>
        </body>
        </html>`;
  }

  /**
   * @description Compare two items
   * @param a
   * @param b
   */
  private _orderByComparator(a: any, b: any): number {
    if (isNaN(parseFloat(a)) || !isFinite(a) || isNaN(parseFloat(b)) || !isFinite(b)) {
      //Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      if (a.toLowerCase() > b.toLowerCase()) return 1;
    } else {
      //Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) return -1;
      if (parseFloat(a) > parseFloat(b)) return 1;
    }

    return 0; //equal each other
  }

  /**
   * @description Used for data table local filter
   * @param results
   * @param key
   * @param fields
   * @param tableColumns
   */
  private _filterByValue(results: any, key: string, fields: any, tableColumns: ParameterCatalog[]): any {
    return results.filter((e: any) => {
      if (typeof e[key] === "number") {
        if (isDevMode() && window.location.hostname === "localhost") {
          // eslint-disable-next-line no-console
          console.log(
            "%c It seems like you are comparing range value with string, make sure your input is filter by range or string, I guess your input (" +
              key +
              ") has to be part of percentageFields in data-table-constants.service.ts, if it's in a special case then please handle it as well.",
            "background: #222; color: red; font-size: 1.5em"
          );
        }
        return e[key] === fields[key]?.value;
      } else {
        const col = tableColumns.find((e) => e.dbFieldName === key);
        return col ? e[col.fieldName]?.toLowerCase().includes(fields[key]?.value?.toLowerCase()) : [];
      }
    });
  }

  /**
   * @description Used for data table local filter
   * @param results
   * @param key
   * @param fields
   */
  private _filterByLov(results: any, key: string, fields: any): any {
    const lovArray: any[] = [];

    if (fields[key]?.lov?.length > 0) {
      fields[key]?.lov?.forEach((e: any) => {
        results.forEach((data: any) => {
          if (Object.values(data)?.includes(e)) {
            lovArray.push(data);
          }
        });
      });
      results = lovArray;
    }

    return results;
  }

  /**
   * @description Used for data table local filter
   * @param results
   * @param key
   * @param fields
   */
  private _filterByRange(results: any, key: string, fields: any, tableColumns: ParameterCatalog[]): any {
    if (key.toLowerCase().includes("date")) {
      results.filter((e: any) => {
        const day = e.day ? e.day : "01";
        const month = e.month ? e.month : "01";
        const year = e.year ? e.year : new Date().getFullYear();
        if (!e[key]) {
          e[key] = new Date(`${day}-${month}-${year}`).getTime();
        }

        e[key] = AdaaHelper.getDubaiTime(e[key]);
      });
    }

    const col = tableColumns.find((e) => e.dbFieldName === key);

    const endValue = +fields[key]!.range!.endNumeric!;
    const startValue = +fields[key]!.range!.startNumeric!;

    return col
      ? (results.filter(
          (e: any) =>
            (this._checkIfNumberAndDefind(endValue) ? +e[col.fieldName] <= endValue : true) &&
            (this._checkIfNumberAndDefind(startValue) ? +e[col.fieldName] >= startValue : true)
        ) ?? [])
      : [];
  }

  /**
   * To get the current route name to be used to save the table state
   * @returns string
   */
  private _getCurrentRouteName(): string {
    let routeName = this._router.url.split("/");
    routeName = routeName.filter((c) => c !== "console" && c !== "" && c !== "dashboard");
    return routeName.length > 0 ? routeName[0] : "";
  }

  /**
   * Return true if the item not allowed to create/edit/delete in archived cycle
   * @param itemId
   * @returns
   */
  private _checkIfItemEffectedByCycle(itemId: number): boolean {
    switch (itemId) {
      case Constants.CONSTANT_PERMISSIONS.ACTIVITIES:
      case Constants.CONSTANT_PERMISSIONS.STRATEGIC_ACTIVITIES:
      case Constants.CONSTANT_PERMISSIONS.INITIATIVES:
      case Constants.CONSTANT_PERMISSIONS.STRATEGIC_PROJECT:
      case Constants.CONSTANT_PERMISSIONS.OBJECTIVES:
      case Constants.CONSTANT_PERMISSIONS.MAIN_OBJECTIVE:
      case Constants.CONSTANT_PERMISSIONS.TRANSFORMATIONAL_ObJECTIVES:
      case Constants.CONSTANT_PERMISSIONS.SERVICES_KHADAMATI:
      case Constants.CONSTANT_PERMISSIONS.DATA_ENTRY:
      case Constants.CONSTANT_PERMISSIONS.SRVKPI:
      case Constants.CONSTANT_PERMISSIONS.OPM:
      case Constants.CONSTANT_PERMISSIONS.EKPI:
      case Constants.CONSTANT_PERMISSIONS.SKPI:
      case Constants.CONSTANT_PERMISSIONS.NKPI:
      case Constants.CONSTANT_PERMISSIONS.DKPI:
      case Constants.CONSTANT_PERMISSIONS.PILLARS:
        return true;
      default:
        return false;
    }
  }

  /**
   * This sill check if the Entity And OrgUnit ddl linked or no based on the table
   * @param configKey
   * @param fieldName
   * @returns boolean
   */
  private _isEntityAndOrgUnitLinked(configKey: string, fieldName: string): boolean {
    return (
      ((configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.PMO_HOMEPAGE_PERF_AGG_CONF_LIST ||
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.AUDIT_DATA ||
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.EKPIS ||
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.NATIONAL_PROJECTS_CONF_LIST ||
        configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.AGM_PROJECTS_CONF_LIST) &&
        fieldName === AdaaHelper.getFieldLanguage("entityName")) ||
      (configKey === Constants.MAIN_TABLE_LIST_CONF_KEY.NTKPIS &&
        fieldName === AdaaHelper.getFieldLanguage("sponsorEntityName"))
    );
  }

  private _checkIfNumberAndDefind(value: number | undefined) {
    return AdaaHelper.isDefined(value) && !isNaN(value);
  }
}
