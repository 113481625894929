@if (!showDIffOnly() || hasAnyDiff()) {
    @if (data().length > 0) {
        <h4 class="mt-4 mb-3 d-block">{{ config().label | translate }}</h4>

        <div class="adaa-datalist" ngbAccordion [closeOthers]="true" [destroyOnHide]="true">
            @for (item of data(); let $i = $index; track $i) {
                @if (!showDIffOnly() || item.hasDiff) {
                    <div class="accordion-item row" ngbAccordionItem>
                        <h2 class="accordion-header col-12 p-0" [id]="'heading' + $i" [attr.tabindex]="$i" ngbAccordionHeader>
                            <button
                                ngbAccordionButton
                                type="button"
                                class="accordion-button fw-normal justify-content-between"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                [class.has-diff]="item.hasDiff || checkChildHasDiff(item.$new)"
                                [class.has-diff]="item.hasDiff || checkChildHasDiff(item.$new)"
                                [attr.data-bs-target]="'#collapse' + $i"
                                [attr.dir]="languageService.direction()"
                            >
                                <span
                                    class="text-start"
                                    [class.text-end]="languageService.direction() === 'rtl'"
                                    style="flex-grow: 2"
                                    [attr.dir]="languageService.direction()"
                                    >{{ item.title }}</span
                                >
                            </button>
                        </h2>

                        <div ngbAccordionCollapse class="accordion-collapse bg-transparent border-0 col-12" [id]="'collapse' + $i">
                            <div class="row">
                                <div class="col-12">
                                    <section class="row d-flex align-content-center">
                                        <div
                                            class="p-2 border border-white tbl-col rounded-start-1 bg-white text-primary fw-bold text-center"
                                            [class.col-6]="showOnlyNew()"
                                            [class.col-4]="!showOnlyNew()"
                                        >
                                            {{ "communication.difference.key" | translate }}
                                        </div>

                                        <div
                                            class="p-2 border border-white tbl-col bg-white text-primary fw-bold text-center"
                                            [class.rounded-end-1]="showOnlyNew()"
                                            [class.col-6]="showOnlyNew()"
                                            [class.col-4]="!showOnlyNew()"
                                        >
                                            {{
                                                !showOnlyNew()
                                                    ? ("communication.difference.new_value" | translate)
                                                    : ("communication.difference.value" | translate)
                                            }}
                                        </div>

                                        @if (!showOnlyNew()) {
                                            <div
                                                class="col-4 p-2 border border-white tbl-col rounded-end-1 bg-white text-primary fw-bold text-center"
                                            >
                                                {{ "communication.difference.old_value" | translate }}
                                            </div>
                                        }
                                    </section>

                                    @for (section of config().config; track $index) {
                                        @switch (section.view) {
                                            @case ("normal") {
                                                <adaa-normal-diff-view
                                                    [config]="section"
                                                    [isNew]="isNew()"
                                                    [showOnlyNew]="showOnlyNew()"
                                                    [newData]="item.$new!"
                                                    [oldData]="item.$old!"
                                                    [showDIffOnly]="showDIffOnly()"
                                                    [isRemoved]="item.isRemoved!"
                                                />
                                            }
                                            @case ("accordion") {
                                                <div class="mx-2">
                                                    <adaa-accordion-diff-view
                                                        [isNew]="isNew()"
                                                        [config]="$asAccordionConfig(section)"
                                                        [showOnlyNew]="showOnlyNew()"
                                                        [newData]="item.$new!"
                                                        [oldData]="item.$old!"
                                                        [props]="props()"
                                                        [showDIffOnly]="showDIffOnly()"
                                                        [parentConf]="item.$new"
                                                        (emitHasDiff)="onChildHasDiff($event)"
                                                    />
                                                </div>
                                            }
                                            @case ("list") {
                                                <adaa-list-diff-view
                                                    [config]="$asListConfig(section)"
                                                    [showOnlyNew]="showOnlyNew()"
                                                    [newData]="item.$new!"
                                                    [oldData]="item.$old!"
                                                    [showDIffOnly]="showDIffOnly()"
                                                    [isRemoved]="item.isRemoved!"
                                                />
                                            }
                                        }
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            }
        </div>
    }
}
