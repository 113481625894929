<adaa-notes-popup #notesPopup (isSaved)="notesSaved.emit(); notesPopup.modalRef.close()" />

<adaa-wf-history #workflowHistory />

<adaa-tp-directions-popup #directionsPopup />

<div class="d-block float-container" [dir]="languageService.direction()">
    @if (showActions()) {
        <div role="navigation" class="d-flex justify-content-end position-relative float-actions">
            <ul role="menu">
                @for (action of floatActionService.actions(); track $index) {
                    <li>
                        <button
                            class="btn bg-white text-secondary py-2 px-4 d-flex justify-content-around align-content-center align-items-center shadow fw-bold"
                            (click)="
                                actionClicked({
                                    action: action.key,
                                    data: action.data,
                                })
                            "
                        >
                            @if (action.icon.type === "fa") {
                                <fa-icon [icon]="getFaIcon(action.icon)" />
                            } @else if (action.icon.type === "adaa") {
                                <i [class]="action.icon.value"></i>
                            }

                            <span class="px-2">{{ action.text | translate }}</span>
                        </button>
                    </li>
                }
            </ul>
        </div>
    }

    <div class="d-flex justify-content-end">
        <button id="page-float-action" class="btn btn-primary rounded-circle shadow border-0" (click)="showActions.set(!showActions())">
            @if (!showActions()) {
                <fa-icon [icon]="ellipsis" size="xl" />
            } @else {
                <fa-icon [icon]="close" size="xl" />
            }
        </button>
    </div>
</div>
