import { CommonModule } from "@angular/common";
import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { AdaaHelper } from "../../../../core/utils";
import { ParameterCatalog } from "../../../models";
import { LanguageService } from "../../../services";

@Component({
  selector: "adaa-form-input-year-range",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: "./form-input-year-range.component.html",
  styleUrl: "./form-input-year-range.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormInputYearRangeComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FormInputYearRangeComponent),
    },
  ],
})
export class FormInputYearRangeComponent implements OnInit, ControlValueAccessor {
  readonly languageService = inject(LanguageService);

  inputControl: FormControl = new FormControl();
  adaaHelper = AdaaHelper;
  fromValue: number;
  toValue: number;

  private _onValidatorChange: () => void;
  private _onTouched: () => void;
  private _onChange: (value: string | null) => void;

  @Input() required: boolean = false;
  @Input() invalid: boolean = false;
  @Input() label: string = "";
  @Input() fromLabel: string = "";
  @Input() toLabel: string = "";
  @Input() fromPlaceholder: string = "";
  @Input() toPlaceholder: string = "";
  @Input() placeholder: string = "";
  @Input() isDisabled: boolean = false;
  @Input() controlName: string | null = null;
  @Input() controlId: string | null = null;
  tempFromValue: number;
  tempToValue: number;

  @Input()
  public set setDefaultValue(value: string) {
    if (value) {
      this.writeValue(value);
    }
  }

  @Input()
  public set setValidator(validatorList: { parameterCatalogs: ParameterCatalog[] }) {
    if (validatorList) {
      if (validatorList.parameterCatalogs) {
        const field = validatorList.parameterCatalogs.find((e) => e.fieldName === this.controlName);

        if (field) {
          this.required = field.mandatory === "Y";
          this.inputControl.updateValueAndValidity();
        }
      }
    }
  }

  @Output() inputChanges = new EventEmitter();

  private _translateService: TranslateService = inject(TranslateService);

  public ngOnInit(): void {
    if (!this.adaaHelper.isDefined(this.controlId)) this.controlId = this.controlName;
    this.inputControl.updateValueAndValidity();

    if (!this.adaaHelper.isDefinedAndNotEmpty(this.fromLabel))
      this.fromLabel = this._translateService.instant("common.tables.range.from");
    if (!this.adaaHelper.isDefinedAndNotEmpty(this.toLabel))
      this.toLabel = this._translateService.instant("common.tables.range.to");
  }

  public writeValue(obj: string): void {
    if (obj === null) {
      this.inputControl.reset();
      return;
    }

    const valueArr = obj.split("-");
    if (!this.adaaHelper.isDefined(valueArr)) return;

    if (this.adaaHelper.isDefined(valueArr[0])) {
      const timestamp = parseFloat(valueArr[0]);
      this.tempFromValue = new Date(timestamp).getFullYear();
    }

    if (this.adaaHelper.isDefined(valueArr[1])) {
      const timestamp = parseFloat(valueArr[1]);
      this.tempToValue = new Date(timestamp).getFullYear();
    }

    this.inputControl.setValue(obj);
  }

  public registerOnChange(fn: (value: string | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this._onValidatorChange = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(value: FormControl): false | { required: boolean } {
    const isRequired =
      (this.required && this.inputControl.value === null) || (this.required && this.inputControl.value === "");
    return (
      isRequired && {
        required: true,
      }
    );
  }

  public tempValueChanged(event: Event, isFrom: boolean): void {
    const year = parseInt((event.target as HTMLInputElement).value);

    if (isFrom) {
      this.tempFromValue = year;
    } else {
      this.tempToValue = year;
    }

    this.valueChanged(event, isFrom);
  }

  public valueChanged(event: Event, isFrom: boolean): void {
    const year = parseInt((event.target as HTMLInputElement).value);

    if (isFrom) {
      const firstDay = new Date(year, 0, 1);
      this.fromValue = firstDay.getTime();
    } else {
      const lastDay = new Date(year, 11, 31);
      this.toValue = lastDay.getTime();
    }

    const value = `${this.fromValue != null ? this.fromValue : ""} - ${this.toValue != null ? this.toValue : ""}`;
    this._onChange(value);
    this.inputChanges.emit(value);
  }
}
