import { inject, Injectable } from "@angular/core";

import { MainResponseModel } from "../models";
import { Notification } from "../models/notifications-config.model";
import { MainService } from "./main.service";

@Injectable({
  providedIn: "root",
})
export class DirectivesApiService {
  private _mainService = inject(MainService);

  public getContent(data: { itemId: number; itemType: number; notificationId: number }) {
    const url = `directives/content/`;

    return this._mainService.sendRequest<MainResponseModel<Notification>>({
      method: "POST",
      url: url,
      data,
    });
  }

  public sendNotification(data: { entityId: number; itemId: number; itemType: number; notificationId: number }) {
    const url = `directives/send/`;

    return this._mainService.sendRequest<MainResponseModel>({
      method: "POST",
      url: url,
      data,
    });
  }
}
