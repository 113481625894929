import { Component, computed, effect, inject, input, output, signal } from "@angular/core";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "@ngx-translate/core";
import isEqualWith from "lodash.isequalwith";

import { AdaaHelper } from "../../../../../../../core/utils";
import { Constants } from "../../../../../../constants/constants";
import { Language } from "../../../../../../constants/enums";
import type { PropTypeModelType } from "../../../../../../models";
import { LanguageService } from "../../../../../../services";
import {
  AccordionSectionConfigType,
  CompositeSectionConfigType,
  ListSectionConfigType,
  type ScreenSectionConfigType,
} from "../../wf-difference.types";
import { ListDiffViewComponent } from "../list-diff-view/list-diff-view.component";
import { NormalDiffViewComponent } from "../normal-diff-view/normal-diff-view.component";

@Component({
  selector: "adaa-accordion-diff-view",
  standalone: true,
  imports: [TranslatePipe, NormalDiffViewComponent, ListDiffViewComponent, NgbAccordionModule],
  templateUrl: "./accordion-diff-view.component.html",
  styleUrl: "./accordion-diff-view.component.scss",
})
export class AccordionDiffViewComponent {
  readonly languageService = inject(LanguageService);

  showOnlyNew = input.required<boolean>();
  config = input.required<AccordionSectionConfigType>();
  isNew = input<boolean>(false);
  newData = input<Record<string, never>>();
  oldData = input<Record<string, never>>();
  props = input<PropTypeModelType[]>([]);
  showDIffOnly = input<boolean>(false);

  Language = Language;

  newList = computed(() => {
    const conf = this.config();
    const data = this.newData();

    if (!data || !conf || !AdaaHelper.isDefined(data?.[conf.field])) return [];
    const list = data?.[conf.field];
    if (!Array.isArray(list)) return [];
    return data?.[conf.field];
  });

  oldList = computed(() => {
    const conf = this.config();
    const data = this.oldData();

    if (!data || !conf || !AdaaHelper.isDefined(data?.[conf.field])) return [];
    const list = data?.[conf.field];
    if (!Array.isArray(list)) return [];
    return data?.[conf.field];
  });

  data = computed(() => {
    const res = this._dataProcessor(this.newList(), this.oldList());
    if (res.some((item) => item.hasDiff)) {
      const parentConf = this.parentConf();
      if (parentConf && "id" in parentConf) {
        this.emitHasDiff.emit({ hasDiff: true, parentId: parentConf.id });
      }
    }
    return res;
  });
  hasAnyDiff = computed(() => {
    const data = this.data();
    return data.some((item) => item.hasDiff);
  });

  emitHasDiff = output<{ hasDiff: boolean; parentId: number }>();
  parentConf = input<{ id: number } | Record<string, never>>();
  childHasDiff = signal<boolean>(false);
  parentDiffId = signal<number | undefined>(undefined);

  readonly $asAccordionConfig = ($s: CompositeSectionConfigType) => $s as AccordionSectionConfigType;
  readonly $asListConfig = ($s: CompositeSectionConfigType) => $s as ListSectionConfigType;

  constructor() {
    effect(() => {
      this._fetchConstants();
    });
  }

  private _dataProcessor($new: Record<string, never>[], $old: Record<string, never>[]) {
    const conf = this.config();
    const id = conf.idKey;
    const output = [];

    if (conf.derivedList && typeof conf.derivedList === "function") {
      $new = conf.derivedList($new as never) as Record<string, never>[];
      $old = conf.derivedList($old as never) as Record<string, never>[];
    }

    // NOTE: removed team member
    for (const item of $old) {
      const index = $new.map((obj) => obj[id]).indexOf(item[id]);

      if (index === -1 || $new[index].status === Constants.OBJECT_STATUS.REMOVE) {
        output.push({
          $new: {},
          $old: item,
          title: conf.title ? conf.title(item as never) : AdaaHelper.getItemValueByToken(item, "name"),
          hasDiff: true,
          isRemoved: true,
        });
      }
    }

    for (const item of $new) {
      const index = $old.map((obj) => obj[id]).indexOf(item[id]);

      // NOTE: new team member added
      if (item.status === Constants.OBJECT_STATUS.REMOVE) continue;
      if (index === -1) {
        output.push({
          $new: item,
          $old: {},
          title: conf.title ? conf.title(item as never) : AdaaHelper.getItemValueByToken(item, "name"),
          hasDiff: true,
        });
      } else {
        // NOTE: check for any differences
        const oldItem = $old[index];
        const diffDetected = this._diffDetected(item, oldItem);
        output.push({
          title: conf.title ? conf.title(item as never) : AdaaHelper.getItemValueByToken(item, "name"),
          $new: item,
          $old: oldItem,
          hasDiff: this.isNew() || diffDetected,
        });
      }
    }

    return output;
  }

  private _diffDetected($new: Record<string, never>, $old: Record<string, never>) {
    const conf = this.config();
    for (const _conf of conf.config) {
      const { field } = _conf;
      const newDataField = $new?.[field];
      const oldDataField = $old?.[field];

      if (_conf.view === "list" && !this._isArrayObjectsEqual(newDataField, oldDataField)) return true;
    }
    return false;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private _isArrayObjectsEqual(arr1: any[], arr2: any[]): boolean {
    return isEqualWith(arr1, arr2, (obj1: any, obj2: any) => {
      if (Array.isArray(obj1) && Array.isArray(obj2)) return undefined;

      if (obj1 && obj2) {
        const value = AdaaHelper.getFieldLanguage("name");
        return obj1[value] === obj2[value];
      }

      return obj1 === obj2;
    });
  }

  private _fetchConstants() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    for (const section of this.config().config) {
      if (section.view !== "normal" && section.view !== "list") continue;

      switch ((section as ScreenSectionConfigType).field) {
        case "targetCategory":
          (section as ScreenSectionConfigType).format = (data: { targetCategory: number }) =>
            that._getTextFromProp(data.targetCategory, that.props() || []);
          break;

        case "targetTypeId":
          (section as ScreenSectionConfigType).format = (data: { targetTypeId: number }) =>
            that._getTextFromProp(data.targetTypeId, that.props() || []);
          break;

        case "publishedState":
          (section as ScreenSectionConfigType).format = (data: { publishedState: number }) =>
            that._getTextFromProp(data.publishedState, that.props() || []);
          break;

        case "actualDataAvailabilityPeriod":
          (section as ScreenSectionConfigType).format = (data: { actualDataAvailabilityPeriod: number }) =>
            that._getTextFromProp(data.actualDataAvailabilityPeriod, that.props() || []);
          break;

        case "ytpCalc":
          (section as ScreenSectionConfigType).format = (data: { ytpCalc: number }) =>
            that._getTextFromProp(data.ytpCalc, that.props() || []);
          break;

        case "trend":
          (section as ScreenSectionConfigType).format = (data: { trend: number }) =>
            that._getTextFromProp(data.trend, that.props() || []);
          break;

        case "dataType":
          (section as ScreenSectionConfigType).format = (data: { dataType: number }) =>
            that._getTextFromProp(data.dataType, that.props() || []);
          break;

        case "measurementUnit":
          (section as ScreenSectionConfigType).format = (data: { measurementUnit: number }) =>
            that._getTextFromProp(data.measurementUnit, that.props() || []);
          break;

        case "frequency":
          (section as ScreenSectionConfigType).format = (data: { frequency: number }) =>
            that._getTextFromProp(data.frequency, that.props() || []);
          break;

        case "perspective":
          (section as ScreenSectionConfigType).format = (data: { perspective: number }) =>
            that._getTextFromProp(data.perspective, that.props() || []);
          break;

        case "classification":
          (section as ScreenSectionConfigType).format = (data: { classification: number }) =>
            that._getTextFromProp(data.classification, that.props() || []);
          break;

        default:
          break;
      }
    }
  }

  private _getTextFromProp(id: number, list: { id: number }[]) {
    const data = list.find((e) => e.id === id);
    if (data) {
      return AdaaHelper.getItemValueByToken(data, "name");
    }
    return "";
  }

  public onChildHasDiff(event: { hasDiff: boolean; parentId: number }) {
    this.childHasDiff.set(event.hasDiff);
    this.parentDiffId.set(event.parentId);
    const parentConf = this.parentConf();
    if (parentConf && "id" in parentConf) {
      this.emitHasDiff.emit({ hasDiff: true, parentId: parentConf.id });
    }
  }

  public checkChildHasDiff(confItem: Record<string, never>) {
    if (confItem) {
      const item = confItem as unknown as { id: number };
      return this.childHasDiff() && item.id === this.parentDiffId();
    }
    return false;
  }
}
